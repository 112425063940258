import { EventEmitter, Output } from '@angular/core';
import { Component, OnInit, OnDestroy, Input } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import { NavigationEnd, NavigationStart, Router } from '@angular/router'

import { Store } from '@ngrx/store'
import * as fromSidebarStore from '@app/sidebar/store'
import * as fromShopStore from '@app/shop/store'

import { ScheduleEvent } from '@app/sidebar/models/schedule-event.model'
import { map } from 'rxjs/operators'
import { FOLLOW_UP_RESOURCE } from '@app/shared/models/followUpTypes.modal'
import { OpenModal } from '@app/modals/store'
import { getContentHTMLWithMention } from '@app/shared'
import * as base64Util from "@app/shared/utils/base64.util";

@Component({
  selector: 'app-header-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css'],
  animations: [],
})
export class HeaderEventsComponent implements OnInit, OnDestroy {
  @Input()
  type = 'notes'
  @Input()
  currentUser
  @Input()
  users = []
  @Output()
  onMobileClick = new EventEmitter<void>()
  @Input()
  options = {
    mobile: false
  }

  events$: Observable<ScheduleEvent[]>
  destroyed$ = new Subject<boolean>();
  isActive: true

  locationStr = location.href;
  FOLLOW_UP_RESOURCE = FOLLOW_UP_RESOURCE

  constructor(
    private coreStore: Store<fromSidebarStore.State>,
    private shopStore: Store<fromShopStore.State>,
    private router: Router,
  ) { }

  ngOnInit() {
    this.events$ = this.coreStore.select(fromSidebarStore.selectSortedScheduleEvents).pipe(
      map(events=>{
        if(!events.length) return []
        events = events.map(e=>{
          return {...e,_contentHTML: getContentHTMLWithMention(e.content,this.users, 'span', 'bg-blue-100 text-blue-900 rounded-lg px-2 py-0.5 text-sm')}
        })
        return events
      })
    )
    // .pipe(
    //   map(events => events.map(e => ({
    //     ...e,
    //     _routerLinkUrl: this.redirect(e.resource, e.resourceId)
    //   })))
    // )
    this.router.events.subscribe(event =>
      event instanceof NavigationStart || event instanceof NavigationEnd ? this.locationStr = event?.url : ''
    )
  }

  deleteEvent(scheduleEvent) {
    this.coreStore.dispatch(
      new fromSidebarStore.DeleteScheduleEvent({ item: scheduleEvent })
    )
  }

  redirect(event) {
    let resource = event.resource
    let resourceId = event.resourceId
    const queryParams = event?.params ? { queryParams: event.params } : {}
    let isLoadboard = window.location.href.indexOf('loadboard') > -1
    this.options.mobile ? this.onMobileClick.emit() : null
    switch (resource) {
      case FOLLOW_UP_RESOURCE.CLAIM:
        return this.router.navigate([`/claims/${resourceId}`])
      case FOLLOW_UP_RESOURCE.ORDER:
        return this.router.navigate(isLoadboard ? [`/loadboard/${resourceId}`] : [`/quotes/${resourceId}`])
      case FOLLOW_UP_RESOURCE.ORDER_BILLING:
        return this.router.navigate(isLoadboard ? [`/loadboard/${resourceId}`] : [`/quotes/${resourceId}`])
      case FOLLOW_UP_RESOURCE.ORDER_BROKER:
        return this.router.navigate(isLoadboard ? [`/loadboard/${resourceId}`] : [`/quotes/${resourceId}`])
      case FOLLOW_UP_RESOURCE.ORDER_COLLECTION:
        return this.router.navigate(isLoadboard ? [`/loadboard`] : ['/quotes'], queryParams)
      case FOLLOW_UP_RESOURCE.ORDER_FILTERS:
        return this.router.navigate(isLoadboard ? [`/loadboard`] : ['/quotes'], queryParams)
      case FOLLOW_UP_RESOURCE.ACCOUNT_CONTACT:
        return this.router.navigate([`/crm/accounts/${resourceId}`])
      case FOLLOW_UP_RESOURCE.CONTACT_NOTE:
        return this.coreStore.dispatch(
          new OpenModal({
            type: 'UPDATE_CONTACT',
            props: { contactId: resourceId, size: 'md', windowClass: "contact-modal" }
          })
        )
      case FOLLOW_UP_RESOURCE.EQUIPMENT:
        return this.coreStore.dispatch(
          new OpenModal({
            type: 'EQUIPMENT_FORM',
            props: { _id: resourceId, carrierId: this.currentUser.company._id, tab: 'Notes', windowClass : "equipment-form-modal" },
          })
        )
      case FOLLOW_UP_RESOURCE.ORDER_ACCOUNTING:
        return this.router.navigate(isLoadboard ? [`/loadboard/${resourceId}/accounting`] : [`/quotes/${resourceId}/accounting`])
      case FOLLOW_UP_RESOURCE.SHOP_ORDER:
        return this.router.navigate([`/shop/${resourceId}`])
      case FOLLOW_UP_RESOURCE.SHOP_SERVICE:
        this.router.navigate([`/shop/${event?._orderId}`])
        return this.coreStore.dispatch(
          new OpenModal({
            type: 'SHOP_SERVICE',
            props: { _id: resourceId, _orderId: event?._orderId }
          }))
      case FOLLOW_UP_RESOURCE.CANDIDATE:
        return this.router.navigate([`/recruiter/candidate/${resourceId}`])
      case FOLLOW_UP_RESOURCE.EXPENSE:
        return this.coreStore.dispatch(
          new OpenModal({
            type: 'EXPENSE',
            props: { _id: resourceId }
          })
        )

      case FOLLOW_UP_RESOURCE.TRIP:
        return this.router.navigate([`/trips/${resourceId}/stops`])
      case FOLLOW_UP_RESOURCE.BOOK_NOW:
        let hash:string;
        if(event._carrierId === this.currentUser.company._id){
          hash = base64Util.encodeUrlSafe(
            JSON.stringify({
              orderId: resourceId,
              carrierId: event._carrierId
            })
          );
        }else{
          hash = base64Util.encodeUrlSafe(
            JSON.stringify({
              orderId: resourceId
            })
          );
        }

        return this.router.navigate([`/v/loadboard/orders/${hash}`])
      default:
        return this.router.navigate([`/${resource}/${resourceId}`])
    }
  }
  // redirect(resource, resourceId, queryParams) {
  //   let path;
  //   if (resource === 'quotes_collection') {
  //     path = `/loadboard/`;
  //   } else if (resource === 'Account') {
  //     path = `/crm/accounts/${resourceId}`;
  //   } else if (resource === 'quote_accounts') {
  //     path = `/loadboard/${resourceId}/accounting`;
  //   } else {
  //     path = `/${resource}/${resourceId}`;
  //   }
  //   this.router.navigate([path], queryParams ? { queryParams } : {});
  // }

  markAsRead(item) {
    if (!item.isRead) {
      this.coreStore.dispatch(
        new fromSidebarStore.MarkScheduleEventAsRead({ items: [item] })
      )
    }
  }

  markAsUnread(item) {
    if (item.isRead) {
      this.coreStore.dispatch(
        new fromSidebarStore.MarkScheduleEventAsUnread({ items: [item] })
      )
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
