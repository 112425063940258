import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Store } from "@ngrx/store"
import moment from "moment"
import { NgxPopperjsPlacements, NgxPopperjsTriggers } from "ngx-popperjs"
import { Subject, takeUntil, tap } from "rxjs"
import * as fromStore from '@app/shared/store'
import { User } from "@app/shared"

@Component({
  selector: "app-dashboard-logistic",
  templateUrl: "./dashboard-logistic.component.html",
  styleUrls: ["./dashboard-logistic.component.scss"]
})

export class DashboardLogisticComponent implements OnInit, OnDestroy {

  destroyed$ = new Subject<boolean>()

  placements = NgxPopperjsPlacements;
  triggers = NgxPopperjsTriggers;

  @Input({required: true})
  set data(val) {
    if(val){
      this._data = val
    }
  }
  _data
  get data(){
    return this._data
  }

  @Output()
  onUpdate = new EventEmitter<any>()

  defaultDate = {
    startDate: moment.utc().startOf('month').toISOString(),
    endDate: moment.utc().endOf('month').toISOString(),
    dataRange: 'thisMonth'
  }

  interval

  sortable = {
    sort: 'incr',
    field: 'name'
  }

  NgxPopperjsPlacements = NgxPopperjsPlacements
  NgxPopperjsTriggers = NgxPopperjsTriggers

  @Input({required: true})
  users: User[]
  @Input()
  filters: {[key: string]: any}


  constructor(
    private store: Store<fromStore.State>,
    private cdr: ChangeDetectorRef,
  ) {

  }

  ngOnInit() {
    this.interval = setInterval(() => {
      this.onUpdate.emit(this.filters)
    }, 18000 * 1000) // 30 minutes
  }


  onSortDispatcherTrips( field, sort) {

    this.sortable = {
        sort,
        field
    }
    if (field == 'name') {
        if (this.sortable.sort == 'incr') {
            this.data.dispatcherTrips?.sort((a, b) => a.name.localeCompare(b.name))
        } else {
            this.data?.dispatcherTrips?.sort((a, b) => b.name.localeCompare(a.name))
        }
    } else {
        if (this.sortable.sort == 'incr') {
            this.data?.dispatcherTrips?.sort((a, b) => a[field] - b[field])
        } else {
            this.data?.dispatcherTrips?.sort((a, b) => b[field] - a[field])
        }
    }
    this.cdr.detectChanges()
  }


  selectUser(event){
    this.onUpdate.emit({excludeIds: event})
  }

  excludeDispatcher(id) {
    this.filters.excludeIds = this.filters.excludeIds || []
    this.filters = { ...this.filters, excludeIds: [...this.filters.excludeIds, id] };
    this.onUpdate.emit(this.filters)
  }

  ngOnDestroy() {
    clearInterval(this.interval)
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
