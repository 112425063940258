<div class="bg-white p-4 rounded-2xl flex flex-col gap-3">
  <div class="flex gap-3">
    <div class="w-1/5">
      @if(!filters?.employeeId){
        <app-search
        [label]="'Employee'"
        [idInfo]="'expense-employee-filter'"
        [resource]="'CONTACT'"
        [defaultFilter]="'all'"
        [method]="'search'"
        [filter]="{limit: 25, accountId: currentUser?.company._id, sort: 'quoteCount', dir: 'desc'}"
        (onChange)="onSet.emit({ employeeId: $event._id, employeeName: $event.name })"
      ></app-search>
      }@else {
          <div class="relative">
          <!-- <app-btn-confirm class="removeBtn" (onConfirm)="onSet.emit({ employeeId: null, employeeName: null })"></app-btn-confirm> -->
          <app-input
          [isReadOnly]="true"
          [value]="filters?.employeeName"
          [label]="'Employee'"
          [display]="{ remove: true }"
          (onRemove)="onSet.emit({ employeeId: null, employeeName: null })"
          ></app-input>
        </div>
      }

    </div>
    <div class="w-1/5">
      <app-select
        [list]="[{key:'expense', name:'By Expense'}, {key:'created', name:'By Created'}, {key:'txn', name:'By Transaction'},]"
        [value]="dateType"
        [label]="'Date'"
        [placeholder]="''"
        (onSave)="setDateType($event)"
      ></app-select>
    </div>
    <div class="w-1/5">
      <app-price [value]="filters?.amount" [label]="'Amount'" [type]="'price'" [debounceEnable]="true" (onSave)="onSet.emit({amount:$event})"></app-price>
    </div>
    <div class="w-1/5">
      <div class="flex flex-col">
        <app-select
          [list]="categoriesArray"
          [value]="filters?.category || ''"
          [label]="'Category'"
          [placeholder]="'Select Category'"
          (onSave)="onSet.emit({category: $event})"
        ></app-select>
        @if(filters?.category){
          <div class="mt-2">
            <app-btn-toggle
              [value]="filters?.excludeCategory"
              [label]="'Exclude Category'"
              [idInfo]="'noInvoice-toggle'"
              (onToggle)="onSet.emit({excludeCategory: !filters.excludeCategory})"
            >
            </app-btn-toggle>
          </div>
        }
      </div>
      
    </div>
    <div class="w-1/5">
      <app-input
        [value]="filters?.desc"
        [idInfo]="'expense-filter-desc'"
        [label]="'Description'"
        [isFilterInput]="true"
        [debounceEnable]="true"
        (onSave)="onSet.emit({desc: $event})"
        ></app-input>
    </div>
  </div>

  <div class="flex gap-3">
    <div class="w-1/5 flex flex-col">
      <app-input
          [value]="filters?.expenseNumber"
          [idInfo]="'expense-filter-expenseNumber'"
          [isFilterInput]="true"
          [label]="'Expense Number'"
          [debounceEnable]="true"
          (onSave)="onSet.emit({expenseNumber: $event})"
      ></app-input>
      <app-multi-select
        class="mt-3"
        [idInfo]="'expense-filter-tags'+ randomId"
        [data]="tags" [value]="filters?.tags"
        [label]="'Tags'" [keyName]="{key:'value', name: 'label'}"
        (onSave)="onSet.emit({ tags: $event })">
      </app-multi-select>
    </div>
    <div class="w-1/5 flex flex-col">
      <app-date
        [startKey]="'dateStart'"
        [parent]="data"
        [options]="{ timezone: false, rounded_top: true }"
        (onSave)="setData($event)"
        [label]="'Date Start'">
      </app-date>
      <app-date
        [startKey]="'dateEnd'"
        [parent]="data"
        [options]="{ timezone: false, rounded_bottom: true }"
        (onSave)="setData($event)">
      </app-date>
    </div>
  <div class="w-1/5 flex flex-col justify-between">
      <div class="relative bg-gray-50 rounded-lg w-full">
        <select
          [ngModel]="filters?.accountChart || ''"
          [ngModelOptions]="{ standalone: true }"
          (ngModelChange)="onSet.emit({accountChart:$event})"
          class="bg-gray-50 block px-2.5 w-full input_style text-sm text-gray-500 bg-transparent font-poppins rounded-lg z-10 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          style="border: 1px solid rgb(209, 213, 219);"
          >
            <option value="">Select</option>
            @for(group of accountsCharts; track $index){
              @if(group.parentId === null){
                <option value="" disabled class="font-weight-bold bg-light">Without Parent</option>
              }
              @for(account of group.accounts; track account._id){
                <option [value]="account._id" [ngClass]="account._id === group.parentId ? ['font-weight-bold bg-light'] : ['ml-3']">{{account._name}}</option>
              }
            }
        </select>
        <label class="absolute bg-gray-50 rounded-2xl text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:py-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Account</label>
          <fieldset class="jss1"><legend class="jss3"><span>Account</span></legend></fieldset>
      </div>
      @if(!filters?.vendorId){
        <app-search
        [label]="'Vendor'"
        [idInfo]="'expense-employee-vendor'"
        [resource]="'CONTACT'"
        [method]="'search'"
        [filter]="{type: 'VENDOR', limit: 25, sort: 'quoteCount', dir: 'desc', isAccount: true}"
        (onChange)="onSet.emit({ vendorId: $event._id, vendorName: $event.name })"
      ></app-search>
      } @else {
        <div class="relative">
          <!-- <app-btn-confirm class="removeBtn" (onConfirm)="onSet.emit({ vendorId: null, vendorName: null })"></app-btn-confirm> -->
          <app-input
          [isReadOnly]="true"
          [value]="filters?.vendorName"
          [label]="'Vendor'"
          [display]="{ remove: true }"
          (onRemove)="onSet.emit({ vendorId: null, vendorName: null })"
          ></app-input>
        </div>
      }


    </div>
    <div class="w-1/5">
      <app-select
        [list]="paymentTypes"
        [value]="filters?.paymentType || ''"
        [label]="'Payment Type'"
        [placeholder]="'Select Payment Type'"
        (onSave)="onSet.emit({paymentType: $event})"
      ></app-select>
      <div class="flex gap-2">
        @if(filters?.paymentType){
          <app-btn-toggle
            [value]="filters?.excludePaymentType"
            [label]="'Exclude Payment'"
            [idInfo]="'excludePaymentType-toggle'"
            (onToggle)="onSet.emit({excludePaymentType: !filters.excludePaymentType})"
          >
          </app-btn-toggle>
        }
        <app-btn-toggle [value]="filters?.reimbursement" [label]="'Reimbursement'" (onToggle)="onSet.emit({reimbursement: !filters?.reimbursement})"></app-btn-toggle>
      </div>
      
    </div>
    @if(filters?.paymentType === 'check') {
      <div class="w-1/5">
        <app-input
        [value]="filters?.checkNo"
        [label]="'Check Number'"
        [isFilterInput]="true"
        [debounceEnable]="true"
        [disableValidation]="true"
        [hightLightIfNotEmpty]="true"
        (onChange)="onSet.emit({checkNo: $event})"
        ></app-input>
      </div>
    }@else {
      <div class="w-1/5"></div>
    }

  </div>
  <div class="flex items-start justify-between">
    <div class="flex items-start">
      <div class="inline-flex border rounded-md mr-3">
        <button
          type="button"
          class="py-2 px-3 border-r"
          [ngClass]="
            filters?.resource?.includes(FOLLOW_UP_RESOURCE.ORDER) ? 'bg-blue-100 text-blue-900' : 'bg-gray-100 text-gray-900'
          "
          (click)="toggleResources(FOLLOW_UP_RESOURCE.ORDER)"
        >
          Order
        </button>
        <button
          type="button"
          class="py-2 px-3 border-r"
          [ngClass]="filters?.resource?.includes(FOLLOW_UP_RESOURCE.TRIP) ? 'bg-blue-100 text-blue-900' : 'bg-gray-100 text-gray-900'"
          (click)="toggleResources(FOLLOW_UP_RESOURCE.TRIP)"
        >
          Trip
        </button>
        <button
          type="button"
          class="py-2 px-3 border-r"
          [ngClass]="
            filters?.resource?.includes(FOLLOW_UP_RESOURCE.CLAIM) ? 'bg-blue-100 text-blue-900' : 'bg-gray-100 text-gray-900'
          "
          (click)="toggleResources(FOLLOW_UP_RESOURCE.CLAIM)"
        >
          Claim
        </button>
        <button
          type="button"
          class="py-2 px-3"
          [ngClass]="!filters?.resource?.length ? 'bg-blue-100 text-blue-900' : 'bg-gray-100 text-gray-900'"
          (click)="onSet.emit({ resource: null })"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>

      @if(filters?.resource?.includes(FOLLOW_UP_RESOURCE.ORDER)){
        <div>
          @if(!filters?.orderName){
            <app-search
            [idInfo]="'order-search'"
            [label]="'Order'"
            [resource]="'ORDER'"
            [method]="'getAll'"
            [options]="{focus: true}"
            (onChange)="onSet.emit({ orderId: $event?.item?._id || null, orderName: $event?.item?.quoteNumber+($event?.item?.ordering ? '.'+$event?.item?.ordering : '')  })"
          ></app-search>
          } @else {
            <div class="relative" role="group" aria-label="Small button group">
              <!-- <app-btn-confirm
                class="removeBtn"
                (onConfirm)="onSet.emit({ orderName: null, orderId: null })"
              ></app-btn-confirm> -->
              <app-input
              [isReadOnly]="true"
              [label]="'Order'"
              [value]="filters?.orderName"
              [display]="{
                remove: true
              }"
              (onRemove)="onSet.emit({ orderName: null, orderId: null })"
              >
              </app-input>
            </div>
          }

        </div>
      }

      @if(filters?.resource?.includes(FOLLOW_UP_RESOURCE.TRIP)){
        <div>
          <div>
            @if(!filters?.tripId){
              <app-search
                [idInfo]="'filter-trip'"
                [label]="'Trip'"
                [resource]="'TRIP'"
                [method]="'getAll'"
                [icon]="'fa-solid fa-search'"
                (onChange)="onSet.emit({ tripId: $event._id, tripName: $event.tripName })"
              ></app-search>
            } @else {
              <div class="relative">
                <!-- <app-btn-confirm
                  class="removeBtn"
                  (onConfirm)="onSet.emit({ tripName: null, tripId: null })"
                ></app-btn-confirm> -->
                <app-input
                [isReadOnly]="true"
                [label]="'Trip'"
                [value]="filters?.tripName"
                [display]="{
                  remove: true
                }"
                (onRemove)="onSet.emit({ tripName: null, tripId: null })"
                >
                </app-input>
              </div>
            }

          </div>
        </div>
      }

    </div>
    <div class="ml-auto">
      <button class="bg-blue-700 px-5 py-1 rounded-lg text-base text-white" (click)="onReset.emit(); data = {}">
        Reset
      </button>
    </div>
  </div>
</div>
