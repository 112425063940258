import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core"
import moment from "moment"
import { NgxPopperjsPlacements, NgxPopperjsTriggers } from "ngx-popperjs"
import { Subject } from "rxjs"
import Chart, { ChartData, ChartTypeRegistry } from 'chart.js/auto'
import { SidebarService } from "@app/core/services/sidebar.service"
import { User } from "@app/shared"

@Component({
  selector: 'app-dashboard-feedback',
  templateUrl: './dashboard-feedback.component.html',
  styleUrls: ['./dashboard-feedback.component.scss']
})

export class DashboardFeedbackComponent implements OnInit, OnDestroy {
  @ViewChild('ratingChart', { static: false })
  private ratingChartRef: ElementRef<HTMLCanvasElement>
  @ViewChild('platformChart', { static: false })
  private platformChartRef: ElementRef<HTMLCanvasElement>

  destroyed$ = new Subject<boolean>()

  placements = NgxPopperjsPlacements;
  triggers = NgxPopperjsTriggers;

  interval: NodeJS.Timeout

  averageRating: string
  ratingChartColors = {
    5: 'rgb(1, 68, 81)',
    3: 'rgb(6, 148, 162)',
    1: 'rgb(213, 245, 246)',
  }

  @Input()
  filters: {[key: string]: any}

  defaultPlatformChartList = [
    {name: "Google"},
    {name: "Yelp"},
    {name: "BBB"},
    {name: 'Haulex'},
    {name: 'Facebook'},
    {name: 'Trustpilot'},
    {name: "Other"},
  ]

  platformChartList: any = []
  platformChartColors = {
    google: 'rgba(5, 122, 85, 1)',
    yelp: 'rgba(224, 36, 36, 1)',
    bbb: 'rgba(26, 86, 219, 1)',
    haulex: 'rgba(0,0,0,1)',
    facebook: 'rgba(26, 86, 219, 1)',
    trustpilot: 'rgba(60, 179, 113)',
    other: 'rgba(250, 202, 21, 1)',
  }

  platformInsideInfo: {
    label?: any,
    percent?: any
  }

  NgxPopperjsPlacements = NgxPopperjsPlacements
  NgxPopperjsTriggers = NgxPopperjsTriggers

  @Input({required: true})
  set users(val: User[]){
    if(val && val.length){
      this._users = val
    }
  }
  _users: User[]
  get users() {
    return this._users
  }

  constructor(
    private sidebarService: SidebarService,
    private cdr: ChangeDetectorRef,
  ) { }

  @Input({required: true})
  set data(val){
    if(val){
      this._data = val
      if (val.ratingData) {
        this.updateRatingChart()
      }
      if (val.platformData) {
        this.updatePlatformChart(val.platformData)
      }
      this.cdr.detectChanges()
    }
  }
  _data
  get data() {
    return this._data
  }

  @Output()
  onUpdate = new EventEmitter<any>()

  platformChart: Chart
  ratingChart: Chart


  ngOnInit() {
    this.interval = setInterval(() => {
      this.onUpdate.emit(this.filters)
    }, 18000 * 1000) // 30 minutes
    // Change tooltip size
    let tooltipSize = 36
    if(window.innerWidth < 2400){
      tooltipSize = 20
    }
    Chart.defaults.plugins.tooltip.titleFont = {
      ...Chart.defaults.plugins.tooltip.titleFont,
      size: tooltipSize
    }

  }


  excludeDispatcher(id: string) {
    let excludeIds = this.filters.excludeIds || []
    excludeIds = [...excludeIds, id]
    this.onUpdate.emit({excludeIds})
  }

  selectUser(event:string[]){
    const isDifferent =
    event?.length !== this.filters?.excludeIds?.length ||
    event?.some((id) => !this.filters?.excludeIds?.includes(id)) ||
    this.filters?.excludeIds?.some((id) => !event?.includes(id));
    if(isDifferent){
      this.onUpdate.emit({excludeIds: event})
    }
  }

  updateChart(prop: 'ratingChart' | 'platformChart', el:HTMLCanvasElement, type: keyof ChartTypeRegistry, data: ChartData, options = {}, tooltip: any = { enabled: false }, insideData: any = {}) {
    if (this[prop]) {
      this[prop].data = data
      return this[prop].update()
    }


    if (el) {
      this[prop] = new Chart(el, {
        type,
        data,
        options: {
          ...options,
          plugins: {
            legend: { display: false },
            tooltip: tooltip
          },
        },
        plugins: [insideData]
      })
    }
  }

  updateRatingChart() {
    let val = this.data.ratingData
    const sumIdCount = val.reduce((acc, entry) => acc + (entry._id * entry.count), 0);
    const sumCount = val.reduce((acc, entry) => acc + entry.count, 0);
    this.averageRating = (sumIdCount / sumCount).toFixed(2);

    this.data.ratingData = val.map(item => {
      let rating = val.find(r => r._id === item._id)
      rating.percent = Number(((item.count / sumCount) * 100).toFixed())
      rating.color = this.ratingChartColors[item._id]
      return rating
    }, {})

    let ratings = this.data.ratingData.sort((a, b) => b.count - a.count);

    this.updateChart('ratingChart', this.ratingChartRef?.nativeElement, 'doughnut', {
      datasets: [
        {
          data: ratings.map(a => a.percent),
          backgroundColor: ratings.map(a => a.color),
          label: 'Rating count'
        }
      ],
      labels: ratings.map(a => a._id),
    },
    {
      cutout: '80%',
      elements: {
        arc: {
          borderWidth: 0
        }
      }
    },
    {
      enabled: true,
      displayColors: false,
      titleMarginBottom: 0,
      callbacks: {
        title: (tooltipItems) => (tooltipItems[0].label > 1 ? tooltipItems[0].label + ' stars' : tooltipItems[0].label + ' star') + ': ' + tooltipItems[0].raw + '%', // popover title
        label: (tooltipItems) => '',
      }
    },
    {
      id: 'insideData',
      beforeDraw(chart) {
        const { ctx, chartArea: { top, width, height, }} = chart;
        ctx.save();
      }
    });
  }

  updatePlatformChart(val) {
    let platforms = val.sort((a, b) => b.count - a.count);
    let countSum = platforms.map(p => p.count).reduce((acc, currentVal) => acc + currentVal, 0);

    this.platformChartList = [ ...this.defaultPlatformChartList ]

    // Add color, type, percent to platform
    platforms = platforms.map(platform => {
      const colorKey = Object.keys(this.platformChartColors)
      .some(el => platform._id === el) ? platform._id : 'other';

      let percent = ((platform.count / countSum) * 100) > 1 ?
      ((platform.count / countSum) * 100).toFixed(0) :
      ((platform.count / countSum) * 100).toFixed(2);

      return {
        ...platform,
        color: this.platformChartColors[colorKey],
        type: colorKey,
        percent: percent,
      };
    })


    // Set data list for displaying with chart
    this.platformChartList = this.platformChartList.map(item => {
      let key = item.name.toLowerCase()
      return platforms.some(el => el.type === key) ? {
        ...(platforms.find(el => el.type === key)),
        name: item.name
      } :
      {
        _id: key,
        count: 0,
        percent: 0,
        color: this.platformChartColors[key],
        type: key,
        name: item.name
      }
    })
    this.platformChartList = this.platformChartList.sort((a, b) => b.count - a.count)
    // We are taking first top 3 platforms as main and group other that have less that top 3 in one type 'other'
    this.platformChartList = this.platformChartList.map((el,ind) => ind <= 2 ? el : {
      ...el,
      type: 'other'
    }
    )

    let otherPlatforms = this.platformChartList.filter(e => e.type === 'other')
    if(otherPlatforms.length){
      otherPlatforms = otherPlatforms.reduce((acc,obj) => {
        return {
          ...acc,
          _id: 'other',
          type: 'other',
          color: this.platformChartColors.other,
          name: 'Other',
          count: (acc.count || 0) + obj.count,
          percent: (Number(acc.percent) || 0) + Number(obj.percent)
        }
      })
    }

    this.platformChartList = this.platformChartList.filter(e => e.type !== 'other')
    this.platformChartList = [ ...this.platformChartList, otherPlatforms]
    this.platformChartList = this.platformChartList.filter(e => e.count)
    let data = this.platformChartList.map(a => a.percent)
    let labels = this.platformChartList.map(a => {
      if (a.type === 'bbb') {
        return a.type.toUpperCase();
      } else {
        return a.type.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
      }
    })

    const colors = this.platformChartList.map(platform => platform.color)
    this.updateChart('platformChart', this.platformChartRef?.nativeElement, 'doughnut',
      {
        datasets: [
          {
            data,
            backgroundColor: colors,
            label: 'Platform'
          }
        ],
        labels,
      },
      {
        cutout: '80%',
        elements: {
          arc: {
            borderWidth: 0
          }
        },
        layout: {
          padding: 10
        },
        hoverOffset: 10,
      },
      {
        enabled: false,
      }
    );


    let label = this.platformChart?.data.labels[0]
    let percent = this.platformChart?.data.datasets[0].data[0]

    this.platformInsideInfo = { label, percent }
    this.cdr.detectChanges()

    if (this.platformChart?.canvas) {
      this.platformChart.canvas.onmousemove = (mousemove) => {
        const points = this.platformChart.getElementsAtEventForMode(mousemove, 'nearest', {intersect: true}, true)
        if (points.length) {
          const datapoint = points[0].index;
          label = this.platformChart.data.labels[datapoint]
          percent = this.platformChart.data.datasets[0].data[datapoint]
          if (this.platformInsideInfo.label !== label) {
            this.platformInsideInfo = { label, percent }
            this.cdr.detectChanges()
          }
        }
      }
    }
  }

  updateFilter(filter){
    this.onUpdate.emit(filter)
  }


  ngOnDestroy() {
    this.sidebarService.setShowNavigation(true)
    this.sidebarService.setShowSidebar(true)

    clearInterval(this.interval)
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }
}
