<div class="flex calendar">
  <div class="table-wrapper" #scrollContainer [ngClass]="{'not_scroll': loading}">
    <table>
      <thead>
        <tr>
          <th class="p-0">
            <div class="relative calendar-search">
              <i class="fa-solid absolute pl-3 left-0 fa-magnifying-glass text-gray-500"></i>
              <input [value]="name" (input)="filterDriver($any($event.target).value)" type="search" id="default-search" class="block default-calendar-search focus:ring-0 w-full border-none text-gray-500 bg-white" [placeholder]="filters?.subType == 'Drivers' ? 'by Driver name...' : 'by Truck name...'">
            </div>
          </th>
          <th class="bg-gray-50 border-none calendar-value-none">.
          </th>
          @for (calendarDay of days; track $index) {
            <th class="date relative">
              <div class="h-full flex items-center date-item">
                {{calendarDay.day}}
              </div>
            </th>
          }
        </tr>
      </thead>
      <tbody>
        @if (filteredRequests?.length && days?.length) {
          @for (request of filteredRequests; track $index) {
            <tr>
              <th class="calendar-list-item w-full flex items-center justify-between">
                <div class="calendar-list-name-wr">
                  {{request}}
                </div>
              </th>
              <td class="bg-gray-50 border-none calendar-value-none">.
              </td>
                @for (calendarDay of days; track $index) {
                  <td
                    *ngIf="!days[$index]?.info || (calendarDay.info && calendarDay?.info[request] && calendarDay?.info[request]?.status === 'DECLINED') || !!(days[$index]?.info && !days[$index]?.info[request]) || !!(!days[$index - 1]?.info) || !!(days[$index - 1]?.info && (days[$index - 1]?.info[request]?._id !== calendarDay?.info[request]?._id))"
                    class="text-center calendar-value relative"              
                    [attr.colspan]="(calendarDay?.info && calendarDay?.info[request] && calendarDay?.info[request]?.status !== 'DECLINED') && (calendarDay?.info && calendarDay?.info[request]?.days) && (days[$index - 1]?.info && (days[$index - 1]?.info[request]?._id !== calendarDay?.info[request]?._id)) || (!days[$index - 1]?.info && calendarDay?.info && calendarDay?.info[request]?.days && calendarDay?.info[request]?.status !== 'DECLINED') ? calendarDay?.info[request]?.days?.length : 0"
                    (click)="calendarDay?.info && calendarDay?.info[request]?.status ? openViewRequest(calendarDay?.info[request], calendarDay?.info[request]?.days) : null"
                    [ngClass]="{
                      'day_off_status': calendarDay?.info && calendarDay?.info[request]?.type === 'DAY_OFF' && calendarDay?.info[request]?.status === 'APPROVED',
                      'pending_status': calendarDay?.info && calendarDay?.info[request]?.status === 'PENDING',
                      'booked_status': calendarDay?.info && calendarDay?.info[request]?.status && calendarDay?.info[request]?.status === 'APPROVED' && calendarDay?.info[request]?.type === 'TRIP',
                    }"
                  >
                    @if (calendarDay.info && calendarDay?.info[request] && calendarDay?.info[request]?.status !== 'DECLINED') {
                      <div class="day_cont" (mouseenter)="
                      calendarDay?.info && calendarDay?.info[request] && calendarDay?.info[request]?.status !== 'DECLINED' 
                        ? (showPopover(calendarDay?.info[request])) 
                        : (hovededSchedule = null)"     [popper]="schedulePopover" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.TOP" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true">
                        @if (calendarDay?.info?.[request] && calendarDay?.info?.[request]?.daysEarly?.length) {
                          <span class="days_early_later">
                            <!-- <i class="fa-solid fa-chevron-left"></i> -->
                            +{{calendarDay?.info?.[request]?.daysEarly?.length}}
                          </span>
                        }
                        <p style="text-align: center;" style="flex: 1;">
                          @if (calendarDay?.info?.[request]) {
                            {{calendarDay.info[request]?.type === 'DAY_OFF' && calendarDay.info[request]?.status === 'APPROVED' ? 'Day Off' :
                            calendarDay.info[request]?.type === 'TRIP' && calendarDay.info[request]?.status === 'APPROVED' ? 'Trip'  :
                            calendarDay.info[request]?.status === 'PENDING' ? 'Request is Pending' : ''}}
                          }
                        </p>
                        @if (calendarDay?.info?.[request] && calendarDay?.info?.[request]?.daysLater?.length) {
                          <span class="days_early_later">
                            <!-- <i class="fa-solid fa-chevron-right"></i> -->
                            +{{calendarDay?.info?.[request]?.daysLater?.length}}
                          </span>
                        }
                      </div>
                    }
                  </td>
                }
            </tr>
          }
        } @else {
          <tr>
            <th class="calendar-list-item w-full flex items-center justify-between">
              <div class="calendar-list-name-wr">
                No Requests
              </div>
            </th>
            <td class="bg-gray-50 border-none calendar-value-none">.
            </td>
              @for (calendarDay of days; track $index) {
                <td class="text-center calendar-value relative">
                </td>
              }
          </tr>
        }
      </tbody>
    </table>
  </div>
</div>

<popper-content #schedulePopover style="z-index: 51">
  @if (hovededSchedule && hovededSchedule?.status === 'PENDING') {
    <div class="popover relative shadow-md">
      <p style="text-wrap: nowrap;" class="text-gray-900">Click to Open</p>
    </div>
  }
  @if (hovededSchedule && hovededSchedule?.status === 'APPROVED' && hovededSchedule?.type !== 'DAY_OFF') {
    <div class="popover_trip relative shadow-md">
      <p style="text-wrap: wrap;" class="text-gray-900 popover_trip_head text-left">Trip</p>
      <div class="popover_trip_data">
        <div class="popover_trip_data_item">
          <div class="text-left">
            <p class="popover_trip_title">Approved By</p>
            <p class="popover_trip_value">{{hovededSchedule?.dispatcher?.name || '-'}}</p>
          </div>
          <div class="text-left popover_trip_right">
            <p class="popover_trip_title">Approve date</p>
            <p class="popover_trip_value">{{hovededSchedule?.approveDate| date: 'MM/dd/yy' : 'UTC'}}</p>
          </div>
        </div>
        <!-- <div class="popover_trip_data_item">
          <div class="text-left">
            <p class="popover_trip_title">Trip date</p>
            <p class="popover_trip_value">3/25/24</p>
          </div>
          <div class="popover_trip_vector">
            <svg xmlns="http://www.w3.org/2000/svg" width="65" height="16" viewBox="0 0 65 16" fill="none">
              <path d="M64.7071 8.70711C65.0976 8.31659 65.0976 7.68342 64.7071 7.2929L58.3431 0.928937C57.9526 0.538413 57.3195 0.538413 56.9289 0.928937C56.5384 1.31946 56.5384 1.95263 56.9289 2.34315L62.5858 8.00001L56.9289 13.6569C56.5384 14.0474 56.5384 14.6805 56.9289 15.0711C57.3195 15.4616 57.9526 15.4616 58.3431 15.0711L64.7071 8.70711ZM-8.74228e-08 9L64 9.00001L64 7.00001L8.74228e-08 7L-8.74228e-08 9Z" fill="#6B7280" style="fill:#6B7280;fill:color(display-p3 0.4196 0.4471 0.5020);fill-opacity:1;"/>
            </svg>
          </div>
          <div class="text-left popover_trip_right">
            <p class="popover_trip_title"></p>
            <p class="popover_trip_value">3/30/24</p>
          </div>
        </div> -->
        <p class="popover_trip_title text-left mt-4">Request Date</p>
        <div class="popover_trip_data_item mt-0">
          <div class="text-left">
            <p class="popover_trip_title"></p>
            <p class="popover_trip_value">{{hovededSchedule?.startDate| date: 'MM/dd/yy' : 'UTC'}}</p>
          </div>
          <div class="popover_trip_vector">
            <div class="date_lines">
              <div class="date_line"></div>
              <div class="date_line"></div>
              <div class="date_line"></div>
              <div class="date_line"></div>
              <div class="date_line"></div>
            </div>
          </div>
          <div class="text-left popover_trip_right">
            <p class="popover_trip_title"></p>
            <p class="popover_trip_value">{{hovededSchedule?.endDate| date: 'MM/dd/yy' : 'UTC'}}</p>
          </div>
        </div>

        <div class="popover_trip_data_item">
          <div class="w-full">
            <p class="popover_trip_title">Description</p>
            <p class="popover_trip_value">{{hovededSchedule?.desc || '-'}}</p>
          </div>
        </div>
      </div>
    </div>
  }
</popper-content>