import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { SharedModule, CoreComponentsModule } from '@app/shared'
import { PipesModule } from '@app/shared/pipes'
import { FormsModule } from '@angular/forms'
import { NgxDatatableModule } from '@swimlane/ngx-datatable'
// import { reducers, effects } from '@app/dashboards/store';
import { DashboardsComponent } from './containers'
import { DashboardCompanyComponent } from './components/dashboard-company/dashboard-company.component'
import { DashboardFilterComponent } from './components/dashboard-filter/dashboard-filter.component'
import { DashboardTvComponent } from './containers/dashboard-tv.component'
import { NgxPopperjsModule } from 'ngx-popperjs'
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { DashboardsService } from './services/dashboards.service'
import { DashboardFeedbackComponent } from './components/dashboard-feedback/dashboard-feedback.component'
import { DashboardLogisticComponent } from './components/dashboard-logistic/dashboard-logistic.component'


const DashboardsRouting = RouterModule.forChild([
    {
        path: '',
        component: DashboardsComponent,
        data: {permissionKey: 'dashboards'},
    },
    {
        path: 'tv',
        component: DashboardTvComponent
    }
])

@NgModule({
    declarations: [
        DashboardsComponent,
        DashboardCompanyComponent,
        DashboardFilterComponent,
        DashboardTvComponent,
        DashboardLogisticComponent,
        DashboardFeedbackComponent
    ],
    imports: [
        DashboardsRouting,
        CommonModule,
        SharedModule,
        CoreComponentsModule,
        PipesModule,
        FormsModule,
        NgxDatatableModule,
        NgxPopperjsModule,
        // StoreModule.forFeature('dashboards', reducers),
        // EffectsModule.forFeature(effects),
    ],
    exports: [
        DashboardsComponent,
        DashboardTvComponent
    ],
    providers: [DashboardsService]
})
export class DashboardsModule {}
