import { NgModule } from '@angular/core';

import { ModalComponent } from '@app/modals/containers/modal-container/modal.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers, effects } from '@app/modals/store';
import {
  UpdateContactModalComponent,
  CustomBOLModalComponent,
  CreateContactModalComponent,
  ViewRequestModalComponent,
  TaskProjectModalComponent
} from '@app/modals/components'
import { CoreComponentsModule, SharedModule } from '@app/shared'
import { LayoutEffects } from '@app/modals/store/effects/layout.effects'
import { PipesModule } from '@app/shared/pipes'
import { FollowUpModalComponent } from './components/follow-up-modal/follow-up-modal.component'
import { ExpensesModule } from '@app/expense/expenses.module'
import { CRMModule } from '@app/crm/crm.module'
import { AccountFormModalComponent } from '@app/crm/components/account-form-modal-component'
import { ClaimsModule } from '@app/claims/claims.module'
import { ClaimAdjustersNotes } from '@app/claims/components/claim-adjusters-notes/claim-adjusters-notes.component'
import { ClaimShopsNearby } from '@app/claims/components/claim-shops-nearby/claim-shops-nearby.component'
import { GoogleMapsModule } from '@angular/google-maps'
import { NewVersionModalComponent } from '@app/core/components/new-version-modal.component'
import { RecruiterModule } from '@app/recruiter/recruiter.module'
import { ShopModule } from '@app/shop/shop.module'
import { NewClaimComponent } from '@app/claims/components/new-claim.component'
import { UsersModule } from '@app/users/users.module'


const COMPONENTS = [
  ModalComponent,
  FollowUpModalComponent,
  UpdateContactModalComponent,
  CustomBOLModalComponent,
  CreateContactModalComponent,
  ViewRequestModalComponent,
  TaskProjectModalComponent,
  AccountFormModalComponent,
  ClaimAdjustersNotes,
  ClaimShopsNearby,
  NewVersionModalComponent,
  NewClaimComponent
];

 

@NgModule({
    imports: [
        StoreModule.forFeature('modals', reducers),
        EffectsModule.forFeature([...effects, LayoutEffects]),
        SharedModule,
        GoogleMapsModule,
        ExpensesModule,
        CRMModule,
        CoreComponentsModule,
        ClaimsModule,
        RecruiterModule,
        ShopModule,
        UsersModule,
        PipesModule
    ],
    exports: COMPONENTS,
    declarations: COMPONENTS,
    providers: []
})
export class ModalsModule {}
