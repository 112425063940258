<div class="w-full flex flex-col justify-center relative main-section" style="padding: 20px 20px 20px 20px">
  <!-- Analythic charts -->
  <div class="flex  justify-between" style="gap: 57px">
    <!-- Rating -->
    <div class="chart-box w-1/2">
      <div class="flex justify-between items-center chart-header">
        <span class="large-black-text font-semibold leading-normal">Average Rating and Breakdown</span>
      </div>
      <div class="chart-info" [ngClass]="{
        'rating-chart-info': data?.ratingData
      }">
        <div class="rating-chart chart-size">
          <canvas #ratingChart (mouseleave)="averageRatingBlock.style.opacity = '100'" (mouseover)="averageRatingBlock.style.opacity = '0'"></canvas>
          <div #averageRatingBlock class="absolute flex flex-col items-center justify-center" style="transition: all 1000ms;">
            <span class="chart-title">{{averageRating}}</span>
            @if(averageRating){
              <span class="chart-subtitle">Reviews</span>
            }
          </div>
        </div>

        @if(data?.ratingData){
          <div class="flex flex-col ratings-start-box-list justify-between">
            @for (rating of data?.ratingData | sort: '_id' :{ reverse: true }; track $index) {
              @switch (rating._id) {
                @case (5) {
                <div class="rating-stars-box-container">
                  <div class="rating-stars-box">
                    <i class="fa-solid fa-star rating-stars-icon text-teal-900"></i>
                    <i class="fa-solid fa-star rating-stars-icon text-teal-900"></i>
                    <i class="fa-solid fa-star rating-stars-icon text-teal-900"></i>
                    <i class="fa-solid fa-star rating-stars-icon text-teal-900"></i>
                    <i class="fa-solid fa-star rating-stars-icon text-teal-900 mr-0"></i>
                  </div>
                  <div class="flex items-center">
                    <div class="progress-bar-rating">
                      <div class="progress-fill bg-teal-900" [ngStyle]="{ 'width': rating.percent + '%' }"></div>
                    </div>
                    <span class="ml-2 rating-list-label large-black-text">{{rating.count || 0}}</span>
                  </div>
                </div>
              }
                @case (3) {
                <div class="rating-stars-box-container">
                  <div class="rating-stars-box">
                    <i class="fa-solid fa-star rating-stars-icon text-teal-500"></i>
                    <i class="fa-solid fa-star rating-stars-icon text-teal-500"></i>
                    <i class="fa-solid fa-star rating-stars-icon text-teal-500 mr-0"></i>
                  </div>
                  <div class="flex items-center">
                    <div class="progress-bar-rating">
                      <div class="progress-fill bg-teal-500" [ngStyle]="{ 'width': rating.percent + '%' }"></div>
                    </div>
                    <span class="ml-2 rating-list-label large-black-text">{{rating.count || 0}}</span>
                  </div>
                </div>
              }
                @case (1) {
                <div class="rating-stars-box-container">
                  <div class="rating-stars-box">
                    <i class="fa-solid fa-star rating-stars-icon text-teal-100"></i>
                  </div>
                  <div class="flex items-center">
                    <div class="progress-bar-rating">
                      <div class="progress-fill bg-teal-100" [ngStyle]="{ 'width': rating.percent + '%' }"></div>
                    </div>
                    <span class="ml-2 rating-list-label large-black-text">{{rating.count || 0}}</span>
                  </div>
                </div>
                }
              }
            }
          </div>
        }@else {
          <div class="empty-chart">
            <p class="empty-chart-info">On this chart, there is no information available for this period.</p>
          </div>
        }
      </div>
    </div>
    <!-- Reviews count -->
    <div class="chart-box w-1/2">
      <div class="flex justify-between items-center chart-header">
        <span class="large-black-text font-semibold leading-normal">Reviews by Directory</span>
        @if(users?.length){
          <button
            [popper]="filterIsOpen"
            [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.LEFT" [popperTrigger]="NgxPopperjsTriggers.click" [popperHideOnClickOutside]="true"
            class="ml-auto bg-blue-700 text-white rounded-lg flex items-center justify-center filter-icon">
            @if(filters?.excludeIds?.length){
              <span>{{filters?.excludeIds?.length}}</span>
            }
            <i class="fa-solid fa-filter "></i>
          </button>
          <!-- {{filters?.excludeIds | json}} -->

          <popper-content #filterIsOpen>
            <div class="bg-white rounded-lg p-3 border multiselect-tv">
              <app-multi-select
               (onSave)="selectUser($event)"
               [idInfo]="'dispatcher-filter-multiselect'"
               [data]="users" [value]="filters?.excludeIds?.length ? filters?.excludeIds : []"
               [keyName]="{key:'_id', name: 'name'}"
               [visibleCount]="1"
               [placeholder]="'Exclude Users'"
               [label]="'Exclude Users'"
               >
              </app-multi-select>
            </div>
          </popper-content>
        }

      </div>
        <div class="chart-info" [ngClass]="{
          'platform-chart-info': data?.platformData
        }">
          <div class="platform-chart chart-size">
            <canvas #platformChart></canvas>
            <div class="absolute flex flex-col items-center justify-center">
              @switch (platformInsideInfo?.label?.toLowerCase()) {
                @case ('google') {
                  <img class="platform-inside-icon" src="../../../../assets/google-icon.svg" alt="">
                }
                @case ('yelp') {
                  <img class="platform-inside-icon" src="../../../../assets/yelp-icon.svg" alt="">
                }
                @case ('bbb') {
                  <img class="platform-inside-icon" src="../../../../assets/bbb-icon.svg" alt="">
                }
                @case ('haulex') {
                  <img class="platform-inside-icon" src="../../../../assets/haulex-icon.svg" alt="">
                }
                @case ('facebook') {
                  <img class="platform-inside-icon" src="../../../../assets/facebook-icon.svg" alt="">
                }
                @case ('trustpilot') {
                  <img class="platform-inside-icon" src="../../../../assets/trustpilot-icon.svg" alt="">
                }
                @case ('other') {
                  <span class="chart-subtitle">Other</span>
                }
              }
              @if(platformInsideInfo?.percent){
                <div class="flex items-center mt-2">
                  <span class="chart-minor-subtitle">Percent:&nbsp;</span>
                  <span class="chart-subtitle">{{platformInsideInfo?.percent}}%</span>
                </div>
              }

            </div>
          </div>

          @if(data?.platformData){
            <div class="platform-list">
              @for (platform of platformChartList; track $index) {
                <div class="flex items-center platform-list-item">
                  <i class="fa-solid fa-circle platform-list-dot" [ngStyle]="{'color': platform?.color}"></i>
                  <span class="font-medium flex justify-center platform-list-percent">{{platform?.count}}</span>
                  <span class=" text-gray-500 font-normal platform-list-label large-black-text">{{platform?.name}}</span>
                  @if (platform._id === 'google') {
                    <img src="../../../../assets/google-icon.svg" alt="google" class="platform-list-google">
                  }
                  @if (platform._id === 'yelp') {
                    <img src="../../../../assets/yelp-icon.svg" alt="yelp" class="platform-list-yelp">
                  }
                  @if (platform._id === 'bbb') {
                    <img src="../../../../assets/bbb-icon.png" alt="bbb" class="platform-list-bbb">
                  }
                  @if (platform._id === 'facebook') {
                    <img src="../../../../assets/facebook-icon.svg" alt="facebook" class="platform-list-facebook">
                  }
                  @if (platform._id === 'trustpilot') {
                    <img src="../../../../assets/trustpilot-icon.svg" alt="trustpilot" class="platform-list-haulex">
                  }
                  @if (platform._id === 'haulex') {
                    <img src="../../../../assets/haulex-icon.svg" alt="haulex" class="platform-list-haulex">
                  }
                </div>
              }
            </div>
          }@else {
            <div class="empty-chart">
              <p class="empty-chart-info">On this chart, there is no information available for this period.</p>
            </div>
          }
        </div>

    </div>
  </div>

  <!-- Dispatchers list -->
  <div class="feedback-list">
    <div>
      <h3 class="feedback-list-title">Top Feedback Dispatcher</h3>
    </div>
    @defer (when data?.dispatchersFeedback?.length) {
      <div class="header">
        <div class="header-title report-name">
            Name
            <button
            (click)="updateFilter({prop: 'name', dir: filters?.dir == 'asc' ? 'desc' : 'asc'})"
            class="sort-button"
          >
            <i class="fa-solid text-gray-500"
              [ngClass]="{
                'fa-chevron-up': (filters?.prop == 'name' && filters?.dir == 'asc'),
                'fa-chevron-down': filters?.dir == 'desc' || filters?.prop !== 'name',
                }"
            ></i>
          </button>
        </div>
        <div class="header-title report-feedback text-center">
            Rating
            <button
            (click)="updateFilter({prop: 'ratingAvg', dir: filters?.dir == 'asc' ? 'desc' : 'asc'})"
            class="sort-button"
          >
            <i class="fa-solid text-gray-500"
              [ngClass]="{
                'fa-chevron-up': (filters?.prop == 'ratingAvg' && filters?.dir == 'asc'),
                'fa-chevron-down': filters?.dir == 'desc' || filters?.prop !== 'ratingAvg',
                }"
            ></i>
          </button>
        </div>
        <div class="header-title report-ratingAvg text-center">
            Feedbacks
            <button
            (click)="updateFilter({prop: 'feedbacksCount', dir: filters?.dir == 'asc' ? 'desc' : 'asc'})"
            class="sort-button"
          >
            <i class="fa-solid text-gray-500"
              [ngClass]="{
                'fa-chevron-up': (filters?.prop == 'feedbacksCount' && filters?.dir == 'asc'),
                'fa-chevron-down': filters?.dir == 'desc' || filters?.prop !== 'feedbacksCount',
                }"
            ></i>
          </button>
        </div>
        <div class="remove-btn-item">

        </div>
      </div>
      <div class="main">
        @for (feedback of data?.dispatchersFeedback; track $index) {
            @if (!feedback?.total) {
                <div class="main-item flex items-baseline">
                    <!-- <div class="main-field number-field">
                      {{$index+1}}.
                    </div> -->
                    <div [ngClass]="{
                      'font-semibold': $index === 2,
                      'medium-black-text': $index === 1,
                      'large-black-text': $index === 0,
                      'font-bold': [0,1].includes($index),
                      'name-field-text': ![0,1].includes($index)
                    }"  class="report-name" >
                      {{$index+1}}.&nbsp;{{feedback?.name}}
                    </div>
                    <div class="report-ratingAvg main-field text-center">
                        <p class="centering-padding"><span [ngClass]="{'no_feedback': !feedback?.ratingAvg}">{{feedback?.ratingAvg?.toFixed(2) || '-'}}</span></p>
                    </div>
                    <div class="report-feedback main-field text-center">
                        <p class="centering-padding"><span [ngClass]="{'no_feedback': !feedback?.feedbacksCount}">{{feedback?.feedbacksCount || '-'}}</span></p>
                    </div>
                    <div class="remove-btn-item">
                      <button (click)="excludeDispatcher(feedback.id)" class="remove-btn">
                        <i class="fa-solid fa-xmark"></i>
                      </button>
                    </div>
                </div>
            }
        }
      </div>
    }@placeholder {
      <app-preloader [type]="'CONTENT_LIST'" [count]="15" [customHeight]="'80px'"></app-preloader>
    }

  </div>


</div>
