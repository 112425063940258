
<div class="h-full" style="margin-bottom: 50px;">
  <button class="back-btn-history" (click)="hideHistory()" *ngIf="showHistory">
    <i class="fas fa-arrow-left"></i>
    <span>Back</span>
  </button>
  <div *ngIf="showHistory" class="claims_history">
    <app-history-logs [resourceType]="'claims'" [resourceId]="claim?._id"></app-history-logs>
  </div>
  <div class="claim-details-header relative" *ngIf="!showHistory">
    <div *ngIf="newDocument" class="modal" (click)="newDocument = false"></div>
    <div class="flex items-center justify-between">
      <div class="flex items-center">
        <button class="back-btn" routerLink="/claims">
          <i class="fa-solid fa-arrow-left"></i>
        </button>
        <span class="claim-autoIncr">Claim #{{claim?.autoIncr}}</span>
        <span class="fa-external_icon cursor-pointer" [routerLink]="['/quotes', claim.orderId]" [popper]="quoteNumber" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.TOP" [popperTrigger]="NgxPopperjsTriggers.hover" [popperHideOnClickOutside]="true">
          <i class="fas fa-external-link-alt"></i>
        </span>
        <popper-content #quoteNumber class="z-50">
          <div class="text-sm text-black rounded-lg shadow-lg bg-gray-100 popover-clime-info">
            <div class="bg-white rounded-lg">
              <div class="px-2 py-2">
                <p class="text-sm font-poppins font-semibold text-center" style="text-wrap: nowrap;">View in Order {{ order?.quoteNumber }}<span *ngIf="order?.ordering">.{{ order?.ordering }}</span></p>
              </div>
            </div>
          </div>
        </popper-content>
        <div class="lastModifyClaim flex items-center">
          <span class="" style="color: #6B7280;">Last Modified: {{ claim.updatedAt | date: 'short' }}</span>
          <span class="lastModifyName text-gray-900 font-medium ml-1">{{ lastModifyClaim?.name }}</span>
        </div>
      </div>
      <div class="flex items-center">
        <button class="shop-order-action" [popper]="claimAction" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.LEFT" [popperTrigger]="NgxPopperjsTriggers.click" [popperHideOnClickOutside]="true">
          <i class="fa-solid fa-bolt"></i>
        </button>
        <button (click)="EDIT_VIEW = !EDIT_VIEW" type="button" class="edit-btn-all text-white flex items-center" [ngClass]="[!EDIT_VIEW ? 'edit-btn-all-edit' : 'view-btn']">
          <i class="fa-solid " [ngClass]="[!EDIT_VIEW ? 'fa-pencil' : 'fa-check']"></i> {{ !EDIT_VIEW ? 'Edit' : 'Save'}}
        </button>
      </div>
    </div>
    <popper-content #claimAction class="z-50">
      <div class="shadow-lg orderAction-popover border border-gray-100 rounded-lg bg-white">
        <button *appHasPermission="'notifications_create'" class="rounded-t-lg" type="button" (click)="notify()">
          <i class="fas fa-bullhorn"></i>
          <span style="text-wrap: nowrap;">Create Notification</span>
        </button>
        <button *ngIf="!(locked || isReadOnly)" (click)="openHistory()" title="Show History">
          <i class="fas fa-history"></i>
          <span>Show History</span>
        </button>
        <button *ngIf="!claim?._gen_pdf" (click)="generatePdf()"
          title="Generate PDF">
          <i class="fa-solid fa-file-pdf"></i>
          <span>Generate PDF</span>
        </button>
        <ng-container *appHasPermission="'claims_delete'">
          <button *ngIf="!(locked || isReadOnly)" type="button" title="Delete ShopOrder" class="border-t border-gray-100 rounded-b-lg" (click)="onRemove.emit(claim); claimAction.hide()">
            <i class="fas fa-trash" style="color: #F05252;"></i>
            <span style="color: #F05252;">Delete Claim</span>
          </button>
        </ng-container>
        <button *ngIf="claim?._gen_pdf">
          <i class="fas fa-spinner fa-pulse"></i> Loading...
        </button>
      </div>
    </popper-content>
  </div>
  <div class="claim-main mt-2">
    <div class="claim-info" *ngVar="(claim?.customCarrierId || order?.carrier?.accountId) as carrierId">
      <div class="relative">
        <div *ngIf="newDocument" class="modal" (click)="newDocument = false"></div>
        <!-- [ngClass]="{'claim_info-no-order': !claim.orderId}" -->
        <div class="claim-info-items">
          <div *ngIf="(claim.type == 'AUTO' || claim.type == 'OTHER')" class="claim_info-left claim-cont-item">
            <div class="claim_info-left-top claim_info-edit" [ngClass]="{'claim_info-no-order': claim.orderId}">
              <div class="claim-info-item-wrapper">
                <div class="claim-info-item flex items-center">
                  <i *ngIf="claim.orderId || !EDIT_VIEW" class="fa-solid fa-hashtag claim-info_icon"></i>
                  <div *ngIf="claim.orderId" class="orderQuoteNumber">
                    <span class="input-read-label">Order</span>
                    <a class="input-read-value" [routerLink]="['/quotes', claim.orderId]">
                      {{ order?.quoteNumber
                      }}<span *ngIf="order?.ordering">.{{ order?.ordering }}</span></a>
                  </div>
                  <div *ngIf="!claim.orderId && !EDIT_VIEW" class="orderQuoteNumber">
                    <span class="input-read-label">Order</span>
                    <span class="input-read-value">-</span>
                  </div>
                  <app-search
                    *ngIf="!claim.orderId && EDIT_VIEW"
                    [idInfo]="'order-search'"
                    [label]="'Order'"
                    [resource]="'ORDER'"
                    [isReadOnly]="locked || isReadOnly"
                    [method]="'getAll'"
                    [options]="{focus: true}"
                    [filter]="{billingContactId: claim?.claimant?.accountId, withoutClaim: true}"
                    (onChange)="setClaimOrder($event)"
                  ></app-search>

                </div>
              </div>
              <div class="claim-info-item-wrapper">
                <div class="claim-info-item flex items-center">
                  <i class="fa-solid fa-hashtag claim-info_icon" [ngStyle]="{'margin-top': !order?.refNumber || order?.refNumber == '' ? '10px' : null}"></i>
                  <div class="orderQuoteNumber">
                    <span class="input-read-label">Order REF</span>
                    <span class="input-read-value">{{order?.refNumber}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="(claim.type == 'AUTO' || claim.type == 'OTHER') && claim?.orderId" class="claim_info-left-bottom">
              <div class="order_accounts">
                @for(item of order?.accounts; track $index){
                  <a
                    class="pay_value" title="{{item.type}} {{item.status}}"
                    href="{{ qbUrl }}/app/{{item.type === 'INVOICE'?'invoice':'recvpayment'}}?txnId={{ item.number }}"
                    [ngClass]="{
                      'text-red-800 bg-red-100': item.status === 'DRAFT',
                      'text-yellow-800 bg-yellow-100': item.status === 'PENDING' || item.status === 'UNPAID',
                      'text-green-800 bg-green-100': item.status === 'PAID',
                      'text-purple-800 bg-purple-100': item.status === 'CREATED',
                      'text-gray-800 bg-gray-100': item.status === 'CANCELLED',
                      'text-amber-800 bg-amber-100': item.status === 'SENT'
                    }"
                  >
                      {{item.type | titlecase}}: {{item?.number}}
                  </a>
                }
              </div>
            </div>
          </div>
          <div class="claim_info-center claim-cont-item relative border-l border-gray-200 relative" [ngClass]="{'claim_info-center-truck': claim.type !== 'AUTO' && claim.type !== 'OTHER'}">
            <div class="remove-order-btn" *ngIf="!(locked || isReadOnly) && claim.orderId">
              <app-btn-confirm (onConfirm)="patchClaim({
                orderId: null,
                location: { address: claim.location.address },
                vehicles: []
              })"></app-btn-confirm>
            </div>
            <div class="claim_info-center-top" [ngClass]="{'claim_info-edit': EDIT_VIEW}">
              <div [ngClass]="{'claim-info-item-wrapper': !EDIT_VIEW}">
                <div class="claim-info-item" [ngClass]="{'claim-info_edit': EDIT_VIEW, 'flex items-center': !EDIT_VIEW}">
                  <i *ngIf="!EDIT_VIEW" class="fa-solid fa-user claim-info_icon"></i>
                  <app-select
                    [idInfo]="'claim-assignee'"
                    [keyName]="{key:'_id', name:'name'}"
                    [list]="users"
                    [value]="claim?.assignee?._id"
                    [options]="{users:true}"
                    [label]="'Assigned:'"
                    [readView]="!EDIT_VIEW"
                    [placeholder]="'Select Assignee'"
                    (onSave)="setAssignee($event)"
                  ></app-select>
                </div>
              </div>
              <div [ngClass]="{'claim-info-item-wrapper': !EDIT_VIEW}">
                <div class="claim-info-item" [ngClass]="{'claim-info_edit': EDIT_VIEW, 'flex items-center': !EDIT_VIEW}">
                  <i *ngIf="!EDIT_VIEW" class="fa-solid fa-print claim-info_icon" [ngStyle]="{'margin-top': !claim?.refNumber || claim?.refNumber == '' ? '10px' : null}"></i>
                  <app-input
                    [idInfo]="'claim-refNumber'"
                    [value]="claim?.refNumber"
                    [label]="'Claim REF'"
                    [type]="'name'"
                    [readView]="!EDIT_VIEW"
                    (onSave)="patchClaim({ refNumber: $event })"
                    >
                  </app-input>
                </div>
              </div>
              <div [ngClass]="{'claim-info-item-wrapper': !EDIT_VIEW}">
                <div class="claim-info-item" [ngClass]="{'claim-info_edit': EDIT_VIEW, 'flex items-center': !EDIT_VIEW}">
                  <i *ngIf="!EDIT_VIEW" class="fa-solid fa-headset claim-info_icon"></i>
                  <app-input
                    [idInfo]="'claim-adjuster'"
                    [value]="claim?.creator?.name"
                    [label]="'Adjuster'"
                    [type]="'name'"
                    [readView]="!EDIT_VIEW"
                    (onSave)="patchAdjuster($event)"
                    >
                  </app-input>
                </div>
              </div>
              <div [ngClass]="{'claim-info-item-wrapper': !EDIT_VIEW}">
                <div class="claim-info-item" [ngClass]="{'claim-info_edit': EDIT_VIEW, 'flex items-center': !EDIT_VIEW}">
                  <i *ngIf="!EDIT_VIEW" class="fa-solid fa-wave-square claim-info_icon"></i>
                  <app-select
                    [idInfo]="'claim-status'"
                    [label]="'Status'"
                    [list]="statuses"
                    [value]="claim.status"
                    [readView]="!EDIT_VIEW"
                    [placeholder]="'Select Status'"
                    [isReadOnly]="locked || isReadOnly"
                    (onSave)="patchClaim({ status: $event })"
                  ></app-select>
                </div>
              </div>
            </div>
            <div class="claim_info-left-bottom" [ngClass]="{'claim_info-center-bottom': claim.type !== 'AUTO' && claim.type !== 'OTHER'}">
              <div class="flex items-center">
                <div class="flex subStatuses-claims">
                  @for(subStatus of subStatuses; track subStatus?.key){
                    <button [disabled]="locked || isReadOnly || !EDIT_VIEW"
                      type="button" class="subStatuses" [ngClass]="{
                        'bg-blue-100': claim?.subStatus === subStatus.key,
                        'btn_hover': claim?.subStatus !== subStatus.key && EDIT_VIEW
                      }"
                      (click)="patchClaim({ subStatus: subStatus.key })"
                      >
                      {{ subStatus.name | titlecase }}
                    </button>
                  }

                </div>
                <div class="flex stages-claims">
                  <button type="button" class="claim-stage" [disabled]="locked || isReadOnly || !EDIT_VIEW" [ngClass]="{
                    'stage-bth-active-new': claim?.stage == 'NEW',
                    'btn_hover': claim?.stage !== 'NEW' && EDIT_VIEW
                  }"
                    (click)="claim?.stage !== 'NEW' ? patchClaim({ stage: 'NEW' }) : null" title="New">
                    <i class="fas fa-fire stage-new"></i>
                  </button>
                  <button type="button" class="claim-stage" [disabled]="locked || isReadOnly || !EDIT_VIEW" [ngClass]="{
                    'stage-bth-active-new': claim?.stage == 'COLD',
                    'btn_hover': claim?.stage !== 'COLD' && EDIT_VIEW
                  }"
                    (click)="claim?.stage !== 'COLD' ? patchClaim({ stage: 'COLD' }) : null" title="Cold">
                    <i class="fas fa-fire stage-cold"></i>
                  </button>
                  <button type="button" class="claim-stage" [disabled]="locked || isReadOnly || !EDIT_VIEW" [ngClass]="{
                    'stage-bth-active-new': claim?.stage == 'WARM',
                    'btn_hover': claim?.stage !== 'WARM' && EDIT_VIEW
                  }"
                    (click)="claim?.stage !== 'WARM' ? patchClaim({ stage: 'WARM' }) : null" title="Warm">
                    <i class="fas fa-fire stage-warm"></i>
                  </button>
                  <button type="button" class="claim-stage" [disabled]="locked || isReadOnly || !EDIT_VIEW" [ngClass]="{
                    'stage-bth-active-new': claim?.stage == 'HOT',
                    'btn_hover': claim?.stage !== 'HOT' && EDIT_VIEW
                  }"
                    (click)="claim?.stage !== 'HOT' ? patchClaim({ stage: 'HOT' }) : null" title="Hot">
                    <i class="fas fa-fire stage-hot"></i>
                  </button>
                  <button *ngIf="claim?.stage && EDIT_VIEW" type="button" class="claim-stage btn_hover"
                    (click)="patchClaim({ stage: '' })">
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="claim_info-right claim-cont-item relative">
            <div class="claim_info-date" [ngClass]="{'view_dates': !EDIT_VIEW}">
              <p class="claim_info-title">Create Date</p>
              <app-date
                [startKey]="'createdAt'"
                [parent]="claim"
                [options]="{ disabled: true }"
                [readView]="!EDIT_VIEW"
              ></app-date>
            </div>
            <div class="claim_info-date" [ngClass]="{'view_dates': !EDIT_VIEW}">
              <p class="claim_info-title">Date of Loss</p>
              <app-date
                [startKey]="'incidentDate'"
                [parent]="claim"
                [readView]="!EDIT_VIEW"
                (onSave)="updateDate('incidentDate', $event.incidentDate)"
                [options]="{ disabled: locked || isReadOnly }"
              ></app-date>
            </div>
            <div class="claim_info-date" [ngClass]="{'view_dates': !EDIT_VIEW}">
              <p class="claim_info-title">Close Date</p>
              <app-date
                [startKey]="'closeDate'"
                [parent]="claim"
                [readView]="!EDIT_VIEW"
                (onSave)="updateDate('closeDate', $event.closeDate)"
                [options]="{ disabled: locked || isReadOnly }"
              ></app-date>
            </div>
          </div>
        </div>
        <div class="flex w-full">
          <div class="claim_details-left">
            <div class="claim_info_item claim-cont-item claim_details">
              <div class="subStatuses_wr">
                <div class="loss-wrapper">
                  <p class="item_det-title">Couse of loss</p>
                  <div class="types-claims">
                    <button [disabled]="locked || isReadOnly || !EDIT_VIEW" type="button" class="claim-type"
                    [ngClass]="{
                      'bg-blue-100': claim?.type == 'AUTO',
                      'btn_hover cursor-pointer': claim?.type !== 'AUTO' && EDIT_VIEW
                    }"
                      (click)="setClaimType('AUTO')">
                      Auto
                    </button>

                    <button [disabled]="locked || isReadOnly || !EDIT_VIEW" type="button" class="claim-type"
                    [ngClass]="{
                      'bg-blue-100': claim?.type == 'TRUCK',
                      'btn_hover cursor-pointer': claim?.type !== 'TRUCK' && EDIT_VIEW
                    }"
                      (click)="setClaimType('TRUCK')">
                      Truck
                    </button>

                    <button [disabled]="locked || isReadOnly || !EDIT_VIEW" type="button" class="claim-type"
                    [ngClass]="{
                      'bg-blue-100': claim?.type == 'OTHER',
                      'btn_hover cursor-pointer': claim?.type !== 'OTHER' && EDIT_VIEW
                    }"
                      (click)="setClaimType('OTHER')">
                      Other
                    </button>
                  </div>
                </div>
                <div class="claim-tags-wrapper" [ngClass]="{'pl-3': locked || isReadOnly || !EDIT_VIEW}">
                  <p class="item_det-title" style="padding-bottom: 6px;" [ngClass]="{'pb-2': locked || isReadOnly || !EDIT_VIEW}">Tags</p>
                  <app-multi-select
                    [idInfo]="'claim-sources'"
                    [keyName]="{key:'value', name: 'label'}"
                    [data]="sources"
                    [value]="source"
                    [readView]="locked || isReadOnly || !EDIT_VIEW"
                    [options]="{search:true, selectAll:true, noLable: true}"
                    [visibleCount]="2"
                    [label]="'Tags'"
                    (onSave)="saveSource($event)">
                  </app-multi-select>

                  <!-- <app-multi-select
                    [idInfo]="'bay-states'+$index"
                    [visibleCount]="8"
                    [options]="{search:true, borderNone: true}"
                    [data]="states" [value]="bay.states"
                    [keyName]="{key:'value', name: 'label'}"
                    [label]="'States:'"
                    (onSave)="setBayState($event, $index)">
                  </app-multi-select>  -->

                </div>
              </div>
            </div>
            <div class="carrier-claimant-info">
              <div class="claim_info_item claim-cont-item-small">
                <p class="item_det-title">Carrier Info</p>
                <div class="claimant-container">
                  <div class="relative info-block-item">
                    <div *ngIf="carrierId" class="account-contact-actions flex items-center">
                      <button *ngIf="!(locked || isReadOnly)" class="edit-btn" (click)="changeContact(carrierId)">
                        <i class="fa-regular fa-pen-to-square"></i>
                      </button>
                      <!-- <div class="remove-btn" *ngIf="!(locked || isReadOnly)">
                        <app-btn-confirm
                          (onConfirm)="removeCarrier('customCarrierId', claim.customCarrierId)"
                        ></app-btn-confirm>
                      </div> -->
                    </div>
                    <p class="item_det-title">Carrier</p>
                    <app-contact-details
                      *ngIf="carrierId"
                      [contact]="getContactById(carrierId)"
                      [main]="carrierId"
                      [isReadOnly]="true"
                      (onNotify)="updateClaimContactNotify($event)"
                      (onUpdate)="updateClaimContact($event)"
                      (onRemove)="removeCarrier('customCarrierId', claim.customCarrierId)"
                      (onAtFault)="patchClaim({ carrierAtFault: $event })"
                      [atFault]="claim?.carrierAtFault"
                      [fields]="{
                        name: { action: 'r' },
                        phones: { action: 'r' },
                        emails: { action: 'r' },
                        notify: !isReadOnly || false,
                        address: { action: 'r' }
                      }"
                      [options]="{fontSemiboldName: true, atFault: true, noEditBtn: true, onAtFaultDisable: !EDIT_VIEW}"
                    ></app-contact-details>
                  <app-search
                    *ngIf="!carrierId"
                    [resource]="'CONTACT'"
                    [idInfo]="'claim-carrier'"
                    [isReadOnly]="locked || isReadOnly"
                    [options]="{noFocus: true, createContact: true}"
                    [createOptions]="{
                      createLocalType: 'customCarrierId'
                    }"
                    [method]="'search'"
                    [filter]="{type: 'CARRIER', limit: 25, sort: 'quoteCount', dir: 'desc'}"
                    (onChange)="patchClaim({ customCarrierId: $event?._id })"
                    (onCreate)="patchClaim({ customCarrierId: $event?.contact?._id })"
                  ></app-search>
                  </div>
                  <div class="relative info-block-item" *ngIf="order?.carrier?.accountId">
                    <div *ngIf="order?.carrier?.accountId" class="account-contact-actions flex items-center">
                      <button class="edit-btn" *ngIf="!(isReadOnly || locked)" (click)="changeContact(getContactById(order?.carrier?.dispatchId)?._id)">
                        <i class="fa-regular fa-pen-to-square"></i>
                      </button>
                    </div>
                    <p class="item_det-title">Dispatch</p>
                    <app-contact-details
                      *ngIf="order?.carrier?.accountId"
                      [contact]="getContactById(order?.carrier?.dispatchId)"
                      (onNotify)="updateClaimContactNotify($event)"
                      (onUpdate)="updateClaimContact($event)"
                      (onAtFault)="setDispatcherFault(order?.carrier?.dispatchId, $event)"
                      [atFault]="claim?.dispatcherAtFault"
                      [options]="{fontSemiboldName: true, atFault: true, noEditBtn: true, onAtFaultDisable: !EDIT_VIEW}"
                      (onRemove)="removeCarrier('customDispatcherId', claim.customCarrierId)"
                      [isReadOnly]="true"
                      [fields]="{
                        name: { action: 'r' },
                        notify: !isReadOnly || false,
                        phones: { action: 'r' },
                        emails: { action: 'r' }
                      }">
                    </app-contact-details>
                  </div>

                  <div class="relative info-block-item" *ngIf="claim?.customDispatcherId">
                    <div *ngIf="claim?.customDispatcherId" class="account-contact-actions flex items-center">
                      <button class="edit-btn" *ngIf="!(isReadOnly || locked)" (click)="changeContact(getContactById(claim?.customDispatcherId)?._id)">
                        <i class="fa-regular fa-pen-to-square"></i>
                      </button>
                    </div>
                    <p class="item_det-title">Dispatch</p>
                    <app-contact-details
                      *ngIf="claim?.customDispatcherId"
                      [contact]="getContactById(claim?.customDispatcherId)"
                      [main]="claim?.customDispatcherId"
                      [isReadOnly]="true"
                      (onNotify)="updateClaimContactNotify($event)"
                      (onUpdate)="updateClaimContact($event)"
                      (onAtFault)="setDispatcherFault(order?.carrier?.dispatchId, $event)"
                      (onRemove)="removeCarrier('customDispatcherId', claim.customCarrierId)"
                      [atFault]="claim?.dispatcherAtFault"
                      [options]="{fontSemiboldName: true, atFault: true, noEditBtn: true}"
                      [fields]="{
                        name: { action: 'r' },
                        phones: { action: 'r' },
                        notify: !isReadOnly || false,
                        emails: { action: 'r' },
                        address: { action: 'r' },
                        edit: !(locked || isReadOnly)
                      }">
                    </app-contact-details>
                  </div>

                  <div *ngIf="!order?.carrier?.dispatchId && !claim?.customDispatcherId && !isReadOnly" class="relative info-block-item">
                  <app-search
                    [label]="'Dispatch'"
                    [resource]="'CONTACT'"
                    [idInfo]="'claim-Dispatch'"
                    [isReadOnly]="locked || isReadOnly"
                    [options]="{noFocus: true, createContact: true,}"
                    [createOptions]="{
                      createLocalType: 'customDispatcherId'
                    }"
                    [method]="'search'"
                    [filter]="{type: 'DISPATCH', limit: 25, sort: 'quoteCount', dir: 'desc'}"
                    (onChange)="patchClaim({ customDispatcherId: $event?._id })"
                    (onCreate)="patchClaim({ customDispatcherId: $event?.contact?._id })"
                  ></app-search>
                </div>

                  <div>
                    @if(order?.carrier?.driverIds){
                      <div>
                        @for(driverId of order?.carrier?.driverIds; track $index){
                          <div class="relative info-block-item">
                            <p class="item_det-title">Driver</p>
                            <div class="account-contact-actions flex items-center">
                              <button *ngIf="!(isReadOnly || locked)" class="edit-btn" (click)="changeContact(driverId)">
                                <i class="fa-regular fa-pen-to-square"></i>
                              </button>
                            </div>
                            <app-contact-details
                              [contact]="getContactById(driverId)"
                              (onNotify)="updateClaimContactNotify($event)"
                              (onUpdate)="updateClaimContact($event)"
                              (onAtFault)="setDriversFault(driverId, $event)"
                              [atFault]="claim?.driversFault?.includes(driverId)"
                              [options]="{fontSemiboldName: true, atFault: true, noEditBtn: true, onAtFaultDisable: !EDIT_VIEW}"
                              [isReadOnly]="locked || isReadOnly || !EDIT_VIEW"
                              [fields]="{
                                name: { action: 'r' },
                                phones: { action: 'r' },
                                notify: !isReadOnly || false,
                                emails: { action: 'r' }
                              }">
                            </app-contact-details>
                          </div>
                        }
                      </div>
                    }

                    @for(driverId of claim?.additionalDriverIds; track $index){
                      <div class="relative info-block-item">
                        <div class="account-contact-actions flex items-center">
                          <button *ngIf="!(isReadOnly || locked)" class="edit-btn" (click)="changeContact(driverId)">
                            <i class="fa-regular fa-pen-to-square"></i>
                          </button>
                          <div class="remove-btn" *ngIf="!(isReadOnly || locked)">
                            <app-btn-confirm
                              (onConfirm)="removeAdditionalDriver(getContactById(driverId))"
                            ></app-btn-confirm>
                          </div>
                        </div>
                        <p class="item_det-title">Additional Driver</p>
                        <app-contact-details class="contact_box-claim"
                          [contact]="getContactById(driverId)"
                          [isReadOnly]="true"
                          [atFault]="claim?.driversFault ? claim?.driversFault.includes(driverId) : false"
                          [options]="{fontSemiboldName: true, atFault: true, noEditBtn: true, onAtFaultDisable: !EDIT_VIEW}"
                          [fields]="{
                            name: { action: 'r' },
                            phones: { action: 'r' },
                            emails: { action: 'r' },
                            notify: !isReadOnly || false
                          }"
                          (onRemove)="removeAdditionalDriver($event)"
                          (onNotify)="updateClaimContactNotify($event)"
                          (onUpdate)="updateClaimContact($event)"
                          (onAtFault)="setDriversFault(driverId, $event)"
                        ></app-contact-details>
                      </div>
                    }
                    <div class="relative info-block-item" style="margin-top: 10px;">
                      <app-search
                        [label]="'Driver'"
                        [resource]="'CONTACT'"
                        [idInfo]="'claim-Dispatch'"
                        [isReadOnly]="locked || isReadOnly"
                        [options]="{noFocus: true, createContact: true}"
                        [createOptions]="{
                          createLocalType: 'driver'
                        }"
                        [method]="'search'"
                        [filter]="{type: 'DRIVER', accountId: order?.carrier?.accountId, limit: 25, sort: 'quoteCount', dir: 'desc', active: true}"
                        (onChange)="addAdditionalDriver($event)"
                        (onCreate)="addAdditionalDriver($event.contact)"
                      ></app-search>
                    </div>
                  </div>
                </div>
              </div>
              <div class="claim_info_item claim-cont-item-small">
                <p class="item_det-title">Claimant Info</p>
                <div class="claimant-container" style="z-index: 5;">
                  @if (claim?.claimant?.accountId) {
                    <div class="relative info-block-item">
                      <div class="account-contact-actions flex items-center">
                        <button *ngIf="!(locked || isReadOnly)" class="edit-btn" (click)="changeContact(claim?.claimant?.accountId)">
                          <i class="fa-regular fa-pen-to-square"></i>
                        </button>
                        <div class="remove-btn" *ngIf="!(locked || isReadOnly) && claim?.type != 'AUTO'">
                          <app-btn-confirm
                            (onConfirm)="removeClaiment('accountId', claim?.claimant?.accountId)"
                          ></app-btn-confirm>
                        </div>
                      </div>
                      <app-contact-details
                        [contact]="getContactById(claim?.claimant?.accountId)"
                        [main]="claim?.claimant?.accountId"
                        (onRemove)="patchClaim({
                          claimant: {
                            contacts: []
                          },
                          vehicles: []
                        })"
                        (onNotify)="updateClaimContactNotify($event)"
                        (onUpdate)="updateClaimContact($event)"
                        [isReadOnly]="true"
                        [fields]="{
                          name: { action: 'r' },
                          type: { action: 'r' },
                          phones: { action: 'r' },
                          emails: { action: 'r' },
                          notify: !isReadOnly || false,
                          contact: false,
                        }"
                        [options]="{fontSemiboldName: true, noEditBtn: true}"
                      >
                      </app-contact-details>
                    </div>
                  }
                  <div *ngIf="claim?.claimant?.contacts?.length">
                    @for(claimant of claim?.claimant?.contacts; track $index){
                      <div class="relative info-block-item">
                        <div class="account-contact-actions flex items-center">
                          <button *ngIf="!(locked || isReadOnly)" class="edit-btn" (click)="changeContact(claimant)">
                            <i class="fa-regular fa-pen-to-square"></i>
                          </button>
                          <div class="remove-btn" *ngIf="!(locked || isReadOnly) && claim?.type != 'AUTO'">
                            <app-btn-confirm
                              (onConfirm)="removeClaiment('claimantContactId', claimant)"
                            ></app-btn-confirm>
                          </div>
                        </div>
                        <app-contact-details
                          [contact]="getContactById(claimant)"
                          [main]="claim?.claimant?.accountId"
                          (onRemove)="patchClaim({
                            claimant: {
                              contacts: []
                            },
                            vehicles: []
                          })"
                          (onNotify)="updateClaimContactNotify($event)"
                          (onUpdate)="updateClaimContact($event)"
                          [isReadOnly]="true"
                          [fields]="{
                            name: { action: 'r' },
                            type: { action: 'r' },
                            phones: { action: 'r' },
                            emails: { action: 'r' },
                            notify: !isReadOnly || false,
                            contact: false,
                          }"
                          [options]="{fontSemiboldName: true, noEditBtn: true}"
                        >
                        </app-contact-details>
                      </div>
                    }
                  </div>
                  <div class="mt-2" *ngIf="claim?.type != 'AUTO'">
                    <app-search
                      [resource]="'CONTACT'"
                      [isReadOnly]="locked || isReadOnly"
                      [idInfo]="'claim-WITH_ORDERS'"
                      [options]="claim?.claimant?.accountId ? {createContact: true} : {noFocus: true, createContact: true, selectType: true}"
                      [createOptions]="{createLocalType: 'claimant'}"
                      [method]="'search'"
                      [filter]="{accountId: claim?.claimant?.accountId, type: claim?.claimant?.accountId ? null : 'WITH_ORDERS', limit: 25, sort: 'quoteCount', dir: 'desc', active: true}"
                      (onChange)="addClaimantContact(claim?.claimant?.accountId ? 'contacts' : 'accountId', $event)"
                      (onCreate)="addClaimantContact(claim?.claimant?.accountId ? 'contacts' : 'accountId', $event.contact)"
                    ></app-search>
                  </div>
                  <p class="lowest-quote">Lowest Quote:<span>${{lowestQuote ? (trunc(lowestQuote) | myCurrency) : '0.00'}}</span></p>
                  <div class="driver-deduct">
                    <div class="driver-item flex items-center">
                      <app-btn-toggle
                        [disabled]="locked || isReadOnly || !EDIT_VIEW"
                        [checkbox]="true"
                        [value]="claim.amounts?.responsible?.driverDeduct"
                        (onToggle)="patchClaim({'amounts.responsible.driverDeduct': !claim.amounts?.responsible?.driverDeduct})"
                      >
                      </app-btn-toggle>
                      <span class="driver-title">Driver Deduct</span>
                    </div>
                    <div class="driver-item flex items-center">
                      <app-btn-toggle
                        [disabled]="locked || isReadOnly || !EDIT_VIEW"
                        [checkbox]="true"
                        [value]="claim.amounts?.responsible?.customerReimbursed"
                        (onToggle)="patchClaim({'amounts.responsible.customerReimbursed': !claim.amounts?.responsible?.customerReimbursed})"
                      >
                      </app-btn-toggle>
                      <span class="driver-title">Customer Reimbursed</span>
                    </div>
                  </div>
                  <div class="pointOfContact">
                    <p class="item_det-title">Point Of Contact</p>
                    <div class="driver-item flex items-center pb-3">
                      <input
                        id="default-checkbox"
                        type="checkbox"
                        name="pointOfContactFilterByClaimant"
                        [(ngModel)]="pointOfContactFilterByClaimant"
                        [disabled]="locked || isReadOnly || !EDIT_VIEW"
                        [ngStyle]="{'cursor': locked || isReadOnly || !EDIT_VIEW ? 'not-allowed' : null}"
                        class="custom-checkbox text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      >
                      <span class="driver-title">Subcontact</span>
                    </div>
                  </div>
                  <div class="relative info-block-item"  *ngIf="claim?.pointOfContactId">
                    <div class="account-contact-actions flex items-center">
                      <button *ngIf="!(locked || isReadOnly || EDIT_VIEW)" class="edit-btn" (click)="changeContact(claim?.pointOfContactId)">
                        <i class="fa-regular fa-pen-to-square"></i>
                      </button>
                      <div *ngIf="!(locked || isReadOnly || !EDIT_VIEW)" class="remove-btn">
                        <app-btn-confirm
                          (onConfirm)="patchClaim({ pointOfContactId: null })"
                        ></app-btn-confirm>
                      </div>
                    </div>
                    <app-contact-details
                      [contact]="getContactById(claim?.pointOfContactId)"
                      (onRemove)="patchClaim({ pointOfContactId: null })"
                      (onNotify)="updateClaimContactNotify($event)"
                      (onUpdate)="updateClaimContact($event)"
                      [addNotifyToNotContact]="false"
                      [isReadOnly]="true"
                      [options]="{fontSemiboldName: true}"
                      [fields]="{
                        name: { action: 'r' },
                        type: { action: 'r' },
                        phones: { action: 'r' },
                        emails: { action: 'r' },
                        contact: false,
                        notify: !isReadOnly || false
                      }"
                    ></app-contact-details>
                  </div>
                  <app-search
                    *ngIf="!claim?.pointOfContactId"
                    [resource]="'CONTACT'"
                    [isReadOnly]="locked || isReadOnly"
                    [idInfo]="'claim-WITH_ORDERS'"
                    [options]="{noFocus: !pointOfContactFilterByClaimant, createContact: true}"
                    [createOptions]="{
                      createLocalType: 'pointOfContactId'
                    }"
                    [method]="'search'"
                    [filter]="{accountId: pointOfContactFilterByClaimant ? claim?.claimant?.accountId : null, limit: 25, sort: 'quoteCount', dir: 'desc', active: true}"
                    (onChange)="patchClaim({ pointOfContactId: $event._id })"
                    (onCreate)="patchClaim({ pointOfContactId: $event.contact._id })"
                  ></app-search>
                </div>
              </div>
            </div>
            <div class="claim_info_item claim-cont-item-small notes_container">
              <div class="notes_container-item" [ngClass]="{'notes_container-item_view': !EDIT_VIEW}">
                <div class="item-notes relative" [ngClass]="{'bg-gray-50 rounded-lg border border-gray-300 p-2.5': EDIT_VIEW}">
                  <p [ngClass]="[EDIT_VIEW ? 'item-notes-title' : 'item_det-title']">Customer Notes</p>
                  <textarea rows="4" [disabled]="!EDIT_VIEW" [value]="claim?.claimantNotes ? claim?.claimantNotes : ''" (blur)="updateNote('claimantNotes', $any($event.target).value)" class="block w-full damage-notes-text text-sm p-0 text-gray-900 border-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Type here"
                    [ngClass]="{'edit_textarea_notes': EDIT_VIEW}"
                  ></textarea>
                  <button *ngIf="claim?.claimantNotes?.length" class="item-notes-btn" [popper]="claimantNotes" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.TOP" [popperTrigger]="NgxPopperjsTriggers.click" [popperHideOnClickOutside]="true">
                    <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
                  </button>
                  <popper-content #claimantNotes class="z-50">
                    <div class="rounded-lg bg-white item-notes-popover-wr">
                      <div class="bg-white rounded-lg">
                        <div class="relative">
                          <div class="flex items-center justify-between">
                            <span class="item-notes-title-popover">Customer Notes</span>
                            <button class="item-notes-close" (click)="claimantNotes.hide()">
                              <i class="fas fa-close text-gray-400"></i>
                            </button>
                          </div>
                          <textarea [disabled]="!EDIT_VIEW"
                            (blur)="updateNote('claimantNotes', $any($event.target).value)"
                            class="block w-full p-0 item-notes-popover-text bg-white rounded-lg border-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Type here"
                          >{{claim?.claimantNotes ? claim?.claimantNotes : ''}}</textarea>
                        </div>
                      </div>
                    </div>
                  </popper-content>
                </div>
                <div class="item-notes relative" [ngClass]="{'bg-gray-50 rounded-lg border border-gray-300 p-2.5': EDIT_VIEW}">
                  <p [ngClass]="[EDIT_VIEW ? 'item-notes-title' : 'item_det-title']">Dispatch Notes</p>
                  <textarea rows="4" [disabled]="!EDIT_VIEW" [value]="claim?.dispatchNotes ? claim?.dispatchNotes : ''" (blur)="updateNote('dispatchNotes', $any($event.target).value)" class="block w-full damage-notes-text text-sm p-0 text-gray-900 border-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Type here"
                    [ngClass]="{'edit_textarea_notes': EDIT_VIEW}"
                  ></textarea>
                  <button *ngIf="claim?.dispatchNotes?.length" class="item-notes-btn" [popper]="dispatchNotes" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.TOP" [popperTrigger]="NgxPopperjsTriggers.click" [popperHideOnClickOutside]="true">
                    <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
                  </button>
                  <popper-content #dispatchNotes class="z-50">
                    <div class="rounded-lg bg-white item-notes-popover-wr">
                      <div class="bg-white rounded-lg">
                        <div class="relative">
                          <div class="flex items-center justify-between">
                            <span class="item-notes-title-popover">Dispatch Notes</span>
                            <button class="item-notes-close" (click)="dispatchNotes.hide()">
                              <i class="fas fa-close text-gray-400"></i>
                            </button>
                          </div>
                          <textarea [disabled]="!EDIT_VIEW"
                            (blur)="updateNote('dispatchNotes', $any($event.target).value)"
                            class="block w-full p-0 item-notes-popover-text bg-white rounded-lg border-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Type here"
                          >{{claim?.dispatchNotes ? claim?.dispatchNotes : ''}}</textarea>
                        </div>
                      </div>
                    </div>
                  </popper-content>
                </div>
                <div class="item-notes relative" [ngClass]="{'bg-gray-50 rounded-lg border border-gray-300 p-2.5': EDIT_VIEW}">
                  <p [ngClass]="[EDIT_VIEW ? 'item-notes-title' : 'item_det-title']">Driver Notes</p>
                  <textarea rows="4" [disabled]="!EDIT_VIEW" [value]="claim?.driverNotes ? claim?.driverNotes : ''" (blur)="updateNote('driverNotes', $any($event.target).value)" class="block w-full damage-notes-text text-sm p-0 text-gray-900 border-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Type here"
                    [ngClass]="{'edit_textarea_notes': EDIT_VIEW}"
                  ></textarea>
                  <button *ngIf="claim?.driverNotes?.length" class="item-notes-btn" [popper]="driverNotes" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.TOP" [popperTrigger]="NgxPopperjsTriggers.click" [popperHideOnClickOutside]="true">
                    <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
                  </button>
                  <popper-content #driverNotes class="z-50">
                    <div class="rounded-lg bg-white item-notes-popover-wr">
                      <div class="bg-white rounded-lg">
                        <div class="relative">
                          <div class="flex items-center justify-between">
                            <span class="item-notes-title-popover">Driver Notes</span>
                            <button class="item-notes-close" (click)="driverNotes.hide()">
                              <i class="fas fa-close text-gray-400"></i>
                            </button>
                          </div>
                          <textarea [disabled]="!EDIT_VIEW"
                            (blur)="updateNote('driverNotes', $any($event.target).value)"
                            class="block w-full p-0 item-notes-popover-text bg-white rounded-lg border-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Type here"
                          >{{claim?.driverNotes ? claim?.driverNotes : ''}}</textarea>
                        </div>
                      </div>
                    </div>
                  </popper-content>
                </div>
                <div class="item-notes relative" [ngClass]="{'bg-gray-50 rounded-lg border border-gray-300 p-2.5': EDIT_VIEW}">
                  <p [ngClass]="[EDIT_VIEW ? 'item-notes-title' : 'item_det-title']">Adjusters Notes</p>
                  <textarea rows="4" [disabled]="!EDIT_VIEW" [value]="claim?.carrierNotes ? claim?.carrierNotes : ''" (blur)="updateNote('carrierNotes', $any($event.target).value)" class="block w-full damage-notes-text text-sm p-0 text-gray-900 border-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Type here"
                    [ngClass]="{'edit_textarea_notes': EDIT_VIEW}"
                  ></textarea>
                  <button *ngIf="claim?.carrierNotes?.length" class="item-notes-btn" [popper]="carrierNotes" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.TOP" [popperTrigger]="NgxPopperjsTriggers.click" [popperHideOnClickOutside]="true">
                    <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
                  </button>
                  <popper-content #carrierNotes class="z-50">
                    <div class="rounded-lg bg-white item-notes-popover-wr">
                      <div class="bg-white rounded-lg">
                        <div class="relative">
                          <div class="flex items-center justify-between">
                            <span class="item-notes-title-popover">Adjusters Notes</span>
                            <button class="item-notes-close" (click)="carrierNotes.hide()">
                              <i class="fas fa-close text-gray-400"></i>
                            </button>
                          </div>
                          <textarea [disabled]="!EDIT_VIEW"
                            (blur)="updateNote('carrierNotes', $any($event.target).value)"
                            class="block w-full p-0 item-notes-popover-text bg-white rounded-lg border-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Type here"
                          >{{claim?.carrierNotes ? claim?.carrierNotes : ''}}</textarea>
                        </div>
                      </div>
                    </div>
                  </popper-content>
                </div>
              </div>
            </div>
          </div>
          <div class="claim_details-right">
            <div class="claim-details-r-top">
              <div class="claim_info_item claim-cont-item height-item vehicl_block_left flex flex-col justify-between">
                <div class="vehicles-wrapper">
                  <!-- <p class="item_det-title">Vehicles</p> -->
                  <div class="vehicles-items">
                    @for(vehicle of vehicles; track vehicle._id){
                      <div class="vehicle">
                        <div class="flex items-center justify-between relative">
                          <div class="flex items-center">
                            <button (click)="openVehicleLocation(vehicle); vehicle.showVehicleLocation = true" class="vehicle-point">
                              <i class="fa-solid fa-location-dot btn_hover"></i>
                            </button>
                            <div class="absolute z-20" style="top: -80px; left: -105px; width: 500px;" *ngIf="vehicle?.showVehicleLocation">
                              <app-vehicle-location
                                [location]="location"
                                [refreshTime]="refreshTime"
                                [status]="order?.status"
                                [address]="fullAddress"
                                (onClose)="vehicle.showVehicleLocation = false"
                              ></app-vehicle-location>
                            </div>
                            <span class="vehicle-name">{{vehicle?.year}} {{vehicle?.make}} {{vehicle?.model}}</span>
                            <i *ngIf="!vehicle?.isOperable" title="INOP" class="fa-solid fa-car-burst vehicle-burst"></i>
                          </div>
                          <div class="flex items-center">
                            <button class="vehicle-search" [disabled]="!EDIT_VIEW" (click)="openAdjustersNotes(vehicle)">
                              <i class="fa-solid fa-magnifying-glass"></i>
                            </button>
                            <button class="vehicle-chevron" (click)="vehicle.showDetails = !vehicle.showDetails">
                              <i class="fa-solid" [ngClass]="[vehicle.showDetails ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
                            </button>
                            <!-- <button class="vehicle-remove" *ngIf="canDeleteVehicle()?.vehicle?.can?.delete" [disabled]="!EDIT_VIEW" (click)="removeVehicle(vehicle)">
                              <i class="fas fa-times"></i>
                            </button> -->
                          </div>
                        </div>
                        <div *ngIf="vehicle?.showDetails" class="vehicle-item-details flex items-center justify-between">
                          <div class="flex flex-col vec-details-item">
                            <span class="vec_key">Year:</span>
                            <span class="vec_value">{{vehicle?.year}}</span>
                          </div>
                          <div class="flex flex-col vec-details-item">
                            <span class="vec_key">VIN:</span>
                            <span class="vec_value">{{vehicle?.VIN}}</span>
                          </div>
                          <div class="flex flex-col vec-details-item">
                            <span class="vec_key">Curb Weight:</span>
                            <span class="vec_value">{{vehicle?.curbWeight}}</span>
                          </div>
                          <div class="flex flex-col vec-details-item">
                            <span class="vec_key">Color:</span>
                            <span class="vec_value">{{vehicle?.color}}</span>
                          </div>
                          <div class="flex flex-col vec-details-item">
                            <span class="vec_key">Type:</span>
                            <span class="vec_value">{{vehicle?.bodyType}}</span>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <!-- <div class="text-right" *ngIf="canDeleteVehicle()?.vehicle?.can?.create">
                  <button class="addVehicle" [disabled]="!EDIT_VIEW" (click)="addVehicle()">Add Vehicle</button>
                </div> -->
              </div>
              <div class="claim_info_item claim-cont-item height-item vehicl_block_right" *ngVar="(claim?.customCarrierId || order?.carrier?.accountId) as carrierId">
                <div class="truck-trailer-wrapper">
                  <p class="item_det-title">Truck / Trailer</p>
                  <div class="truck-trailer-item relative">
                    <p class="claim_info-title">Truck</p>
                    <app-input
                      *ngIf="!claim.equipment?.truck && !EDIT_VIEW"
                      [value]="'No track selected'"
                      [readView]="true"
                    >
                    </app-input>
                    <app-search
                      *ngIf="!claim.equipment?.truck && EDIT_VIEW"
                      [idInfo]="'equipment-truck'"
                      [label]="'Unit'"
                      [resource]="'EQUIPMENT'"
                      [isReadOnly]="!carrierId || !EDIT_VIEW"
                      [options]="{focus: true}"
                      [method]="'search'"
                      [filter]="{carrierId: carrierId, type: 'TRUCK'}"
                      (onChange)="setUnit($event._id, $event.name, 'truck')"
                    ></app-search>
                    <app-input
                      *ngIf="claim.equipment?.truck"
                      [value]="claim.equipment.truck.name"
                      [readView]="!EDIT_VIEW"
                      [options]="{canRemove: true}"
                      (onRemove)="removeUnit('truck')"
                    >
                    </app-input>
                  </div>
                  <div class="truck-trailer-item relative">
                    <p class="claim_info-title">Trailer</p>
                    <app-input
                      *ngIf="!claim.equipment?.trailer && !EDIT_VIEW"
                      [value]="'No trailer selected'"
                      [readView]="true"
                    >
                    </app-input>
                    <app-search
                      *ngIf="!claim.equipment?.trailer && EDIT_VIEW"
                      [idInfo]="'equipment-trailer'"
                      [label]="'Unit'"
                      [resource]="'EQUIPMENT'"
                      [isReadOnly]="!carrierId || !EDIT_VIEW"
                      [options]="{focus: true}"
                      [method]="'search'"
                      [filter]="{carrierId: carrierId, type: 'TRAILER'}"
                      (onChange)="setUnit($event._id, $event.name, 'trailer')"
                    ></app-search>
                    <app-input
                      *ngIf="claim.equipment?.trailer"
                      [value]="claim.equipment.trailer.name"
                      [options]="{canRemove: true}"
                      [readView]="!EDIT_VIEW"
                      (onRemove)="removeUnit('trailer')"
                    >
                    </app-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="claim_info_item claim-cont-item damage-notes-wrapper">
              <div class="vehicles-wrapper">
                <p class="item_det-title">Damage Notes</p>
                <!-- [disabled]="!EDIT_VIEW" -->
                <div class="" [ngClass]="{'damage-notes_items': claim?.damageNotes?.length > 1}">
                  @for(note of claim?.damageNotes; track $index){
                    <div class="damage-notes_item">
                      <p class="">{{note?.title}}</p>
                      @if(!note?.content?.length){
                        <label [for]="setStringFor(note?.title)" class="no_damage_note" [ngClass]="{'hide_no_damage_note': note.edit}" (click)="EDIT_VIEW ? clickEmptyNote(note) : null">
                          <p>No Damage Notes Here.</p>
                        </label>
                      }@else if(note?.content?.length || note.edit){
                        <textarea
                          [id]="setStringFor(note?.title)"
                          autogrow
                          [disabled]="!EDIT_VIEW"
                          class="block mt-1 damage-notes-textarea w-full text-sm text-gray-900 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Write your thoughts here..."
                          [value]="note?.content ? note?.content : ''"
                          (blur)="updateDamageNote(note, $any($event.target).value); note.edit = false"
                        ></textarea>
                      }
                    </div>
                  }
                  <!-- [ngClass]="{'bg-gray-50 border border-gray-300 mt-1': EDIT_VIEW, 'p-0': !EDIT_VIEW}" -->

                  <!-- <div class="damage-notes_item">
                    <p class="">2020 Tesla Model S</p>
                    <textarea
                      id="message"
                      autogrow
                      [disabled]="!EDIT_VIEW"
                      class="block damage-notes-textarea w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Write your thoughts here..."
                    ></textarea>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="claim_info_item claim-cont-item order-info-wrapper" [ngClass]="{'edit_stops': EDIT_VIEW}">
              <div class="">
                <div *ngIf="claim.type === 'AUTO' || claim.type === 'OTHER'" class="claim_data-delivery">
                  <div *ngIf="claim.location?.originId" class="claim_pickup_item">
                    <p class="item_det-title claim_pickup_title">Order Info</p>
                    <div class="relative">
                      <app-order-stop
                      [quote]="order"
                      [getFromLocal]="true"
                      [currentUser]="currentUser"
                      [stopType]="'Pickup'"
                      [stopId]="claim.location?.originId"
                      [users]="users"
                      [options]="{ edit_view: true, address_edit: true, isNotes: true }"
                      [contactTypes]="contactTypes"
                      [EDIT_VIEW]="EDIT_VIEW"
                      ></app-order-stop>
                    </div>
                  </div>
                  <div *ngIf="claim.location?.destinationId" class="claim_delivery_item relative">
                    <app-order-stop
                      [idInfo]="'claim-stop-delivery'"
                      [quote]="order"
                      [getFromLocal]="true"
                      [currentUser]="currentUser"
                      [stopType]="'Delivery'"
                      [EDIT_VIEW]="EDIT_VIEW"
                      [stopId]="claim.location?.destinationId"
                      [users]="users"
                      [options]="{ address_edit: true, isNotes: true, edit_view: true, fontSemiboldName: true, adit_btn: true }"
                      [contactTypes]="contactTypes"
                    ></app-order-stop>
                  </div>
                  <div class="claim_payment-wrapper" [ngClass]="{'border-l border-gray-200': claim.location?.originId || claim.location?.destinationId, 'ml-0': !claim.location?.originId || !claim.location?.destinationId}">
                    <p class="item_det-title claim_payment_title">Payment Info</p>
                    <div class="claim_payment_info">
                      <div class="transport-paid">
                        <app-btn-toggle [options]="{ disabled: isReadOnly || locked || !EDIT_VIEW }"
                          [value]="claim?.transportPaid" [label]="'Transport Paid'"
                          (onToggle)="patchClaim({ transportPaid: $event })">
                        </app-btn-toggle>

                        <div class="flex flex-col">
                          <div class="claim-payd_item" [ngClass]="{'flex items-center justify-between border-b border-gray-200 pb-3': !EDIT_VIEW}">
                            <p class="claim_info-title">How Much Was Paid</p>
                            <div class="flex items-center claim_pay_view" *ngIf="!EDIT_VIEW"><i class="fa-solid fa-dollar-sign icon_currency ml-1"></i><span>{{claim?.realPaidAmount | myCurrency}}</span></div>
                            <app-price
                              *ngIf="EDIT_VIEW"
                              [isReadOnly]="locked || isReadOnly || !EDIT_VIEW"
                              [type]="'price'"
                              [title]="'claim?.realPaidAmount'"
                              [value]="claim?.realPaidAmount"
                              [icon]="'fa-solid fa-dollar-sign'"
                              [options]="{iconCurrency: true}"
                              (onSave)="patchClaim({ realPaidAmount: $event })"
                            ></app-price>
                          </div>
                          <div class="claim-payd_item" [ngClass]="{'flex items-center justify-between': !EDIT_VIEW}">
                            <p class="claim_info-title">Payment Amount</p>
                            <div class="flex items-center claim_pay_view" *ngIf="!EDIT_VIEW"><i class="fa-solid fa-dollar-sign icon_currency ml-1"></i><span>{{claim?.paidAmount | myCurrency}}</span></div>
                            <app-price
                              *ngIf="EDIT_VIEW"
                              [isReadOnly]="locked || isReadOnly || !EDIT_VIEW"
                              [type]="'price'"
                              [title]="'claim?.paidAmount'"
                              [icon]="'fa-solid fa-dollar-sign'"
                              [value]="claim?.paidAmount"
                              [options]="{iconCurrency: true}"
                              (onSave)="patchClaim({ paidAmount: $event })"
                            ></app-price>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="assign-shop" [ngClass]="{'pt-5 border-t border-gray-200': claim.type == 'AUTO' || claim.type == 'OTHER'}">
                  <div class="assign_wr">
                    <div class="flex items-center justify-between w-full">
                      <p class="item_det-title">Assign shop</p>
                      <button *ngIf="vehicles?.length" class="show-nearby" (click)="openShopsNearby()">Shops Nearby</button>
                    </div>
                    <div class="claim-shop">
                      <div *ngIf="!lowestQuoteShop?._id" class="lowestQuoteShop-empty">
                        <p class="text-center" style="flex: 1">Please select shop</p>
                      </div>
                      <app-contact-details
                        *ngIf="lowestQuoteShop?._id"
                        [contact]="lowestQuoteShop"
                        [main]="lowestQuoteShop?._id"
                        [fields]="{
                          name: { action: 'r' },
                          phones: { action: 'r' },
                          emails: { action: 'r' },
                          notify: !isReadOnly || false,
                          address: { action: 'r' }
                        }"
                        [options]="{fontSemiboldName: true}"
                        (onRemove)="putClaim({shop: null})"
                      ></app-contact-details>
                    </div>
                  </div>
                  <div class="estimates">
                    <p class="item_det-title">Estimates</p>
                    <div class="estimates-block">
                      <div class="estimates-item"
                        [ngClass]="{
                          'estimates-item-view py-2 px-2 rounded-lg': !EDIT_VIEW,
                          'active_estimate': claim?.estimates?.shopsFirst?.isLowestQuote && !EDIT_VIEW,
                          'not_active_estimate': !claim?.estimates?.shopsFirst?.isLowestQuote && !EDIT_VIEW && lowestQuote && lowestQuote !== ''
                          }">
                        <div *ngIf="EDIT_VIEW" [title]="!claim?.estimates?.shopsFirst?.price ? 'Please enter price' : ''">
                          <app-btn-toggle
                            [idInfo]="'shopsFirst'"
                            [checkbox]="true"
                            [disabled]="!claim?.estimates?.shopsFirst?.price || !EDIT_VIEW"
                            [value]="claim?.estimates?.shopsFirst?.isLowestQuote"
                            (onToggle)="setActiveShopId('shopsFirst', $event)"
                          ></app-btn-toggle>
                        </div>
                        <span class="estimates-number act_it">1.</span>
                        <div *ngIf="!EDIT_VIEW" class="flex items-center"><i class="fa-solid fa-dollar-sign icon_currency act_it_icon"></i><span class="claim_estimates_view act_it font-bold">{{(claim?.estimates?.shopsFirst?.price || 000) | myCurrency}}</span></div>
                        <p *ngIf="!EDIT_VIEW" class="claim_estimates_view font-bold act_it">{{claim?.estimates?.shopsFirst?.shop?.name}}</p>
                        <div *ngIf="EDIT_VIEW" class="flex items-center" style="flex: 1;">
                          <div class="estimates-pay">
                            <app-price
                              [type]="'price'"
                              [value]="claim?.estimates?.shopsFirst?.price || ''"
                              [isReadOnly]="!EDIT_VIEW"
                              [icon]="'fa-solid fa-dollar-sign'"
                              [options]="{iconCurrency: true}"
                              (onSave)="setShopPrice('shopsFirst', $event)"
                            ></app-price>
                          </div>
                          <div class="estimates-shop" *ngIf="EDIT_VIEW">
                            <app-search
                              *ngIf="!claim?.estimates?.shopsFirst?.shop?._id"
                              [resource]="'CONTACT'"
                              [label]="'Truck Shop'"
                              [isReadOnly]="!EDIT_VIEW"
                              [idInfo]="'claim-first'"
                              [options]="{noFocus: true}"
                              [method]="'search'"
                              [filter]="{type: 'TRUCK SHOP', limit: 25, sort: 'quoteCount', dir: 'desc', active: true, isAccount: true}"
                              (onChange)="setShop('shopsFirst', $event)"
                            ></app-search>
                            <app-input
                              *ngIf="claim?.estimates?.shopsFirst?.shop?._id"
                              [idInfo]="'first'"
                              [label]="'Truck Shop'"
                              [isReadOnly]="true"
                              [value]="claim?.estimates?.shopsFirst?.shop?.name"
                              [options]="{canRemove: EDIT_VIEW}"
                              (onRemove)="deleteShop('shopsFirst')"
                            ></app-input>
                          </div>
                        </div>
                      </div>
                      <div class="estimates-item"
                      [ngClass]="{
                        'estimates-item-view py-2 px-2 rounded-lg': !EDIT_VIEW,
                        'active_estimate': claim?.estimates?.shopsMiddle?.isLowestQuote && !EDIT_VIEW,
                        'not_active_estimate': !claim?.estimates?.shopsMiddle?.isLowestQuote && !EDIT_VIEW && lowestQuote && lowestQuote !== ''
                      }">
                        <div *ngIf="EDIT_VIEW" [title]="!claim?.estimates?.shopsMiddle?.price ? 'Please enter price' : ''">
                          <app-btn-toggle
                            *ngIf="EDIT_VIEW"
                            [idInfo]="'shopsMiddle'"
                            [checkbox]="true"
                            [disabled]="!claim?.estimates?.shopsMiddle?.price || !EDIT_VIEW"
                            [value]="claim?.estimates?.shopsMiddle?.isLowestQuote"
                            (onToggle)="setActiveShopId('shopsMiddle', $event)"
                          ></app-btn-toggle>
                        </div>
                        <span class="estimates-number act_it">2.</span>
                        <div *ngIf="!EDIT_VIEW" class="flex items-center"><i class="fa-solid fa-dollar-sign icon_currency act_it_icon"></i><span class="font-bold claim_estimates_view act_it">{{(claim?.estimates?.shopsMiddle?.price || 000) | myCurrency}}</span></div>
                        <p *ngIf="!EDIT_VIEW" class="claim_estimates_view font-bold act_it">{{claim?.estimates?.shopsMiddle?.shop?.name}}</p>
                        <div *ngIf="EDIT_VIEW" class="flex items-center" style="flex: 1;">
                          <div class="estimates-pay">
                            <app-price
                              [type]="'price'"
                              [value]="claim?.estimates?.shopsMiddle?.price || ''"
                              [icon]="'fa-solid fa-dollar-sign'"
                              [isReadOnly]="!EDIT_VIEW"
                              [options]="{iconCurrency: true}"
                              (onSave)="setShopPrice('shopsMiddle', $event)"
                            ></app-price>
                          </div>
                          <div class="estimates-shop">
                            <app-search
                              *ngIf="!claim?.estimates?.shopsMiddle?.shop?._id"
                              [resource]="'CONTACT'"
                              [label]="'Truck Shop'"
                              [isReadOnly]="!EDIT_VIEW"
                              [idInfo]="'truck-shop-middle'"
                              [options]="{noFocus: true}"
                              [method]="'search'"
                              [filter]="{type: 'TRUCK SHOP', limit: 25, sort: 'quoteCount', dir: 'desc', active: true, isAccount: true}"
                              (onChange)="setShop('shopsMiddle', $event)"
                            ></app-search>
                            <app-input
                              *ngIf="claim?.estimates?.shopsMiddle?.shop?._id"
                              [idInfo]="'middle'"
                              [label]="'Truck Shop'"
                              [isReadOnly]="!EDIT_VIEW"
                              [value]="claim?.estimates?.shopsMiddle?.shop?.name"
                              [options]="{canRemove: EDIT_VIEW}"
                              (onRemove)="deleteShop('shopsMiddle')"
                            ></app-input>
                          </div>
                        </div>
                      </div>
                      <div class="estimates-item"
                      [ngClass]="{
                        'estimates-item-view py-2 px-2 rounded-lg': !EDIT_VIEW,
                        'active_estimate': claim?.estimates?.shopsLast?.isLowestQuote && !EDIT_VIEW,
                        'not_active_estimate': !claim?.estimates?.shopsLast?.isLowestQuote && !EDIT_VIEW && lowestQuote && lowestQuote !== ''
                      }">
                      <div *ngIf="EDIT_VIEW" [title]="!claim?.estimates?.shopsLast?.price ? 'Please enter price' : ''">
                        <app-btn-toggle
                          *ngIf="EDIT_VIEW"
                          [idInfo]="'shopsLast'"
                          [checkbox]="true"
                          [disabled]="!claim?.estimates?.shopsLast?.price || !EDIT_VIEW"
                          [value]="claim?.estimates?.shopsLast?.isLowestQuote"
                          (onToggle)="setActiveShopId('shopsLast', $event)"
                        ></app-btn-toggle>
                      </div>
                        <span class="estimates-number act_it">3.</span>
                        <div *ngIf="!EDIT_VIEW" class="flex items-center"><i class="fa-solid fa-dollar-sign icon_currency act_it_icon"></i><span class="font-bold claim_estimates_view act_it">{{(claim?.estimates?.shopsLast?.price || 000) | myCurrency}}</span></div>
                        <p *ngIf="!EDIT_VIEW" class="claim_estimates_view font-bold act_it">{{claim?.estimates?.shopsLast?.shop?.name}}</p>
                        <div *ngIf="EDIT_VIEW" class="flex items-center" style="flex: 1;">
                          <div class="estimates-pay">
                            <app-price
                              [type]="'price'"
                              [value]="claim?.estimates?.shopsLast?.price || ''"
                              [icon]="'fa-solid fa-dollar-sign'"
                              [isReadOnly]="!EDIT_VIEW"
                              [options]="{iconCurrency: true}"
                              (onSave)="setShopPrice('shopsLast', $event)"
                            ></app-price>
                          </div>
                          <div class="estimates-shop">
                            <app-search
                              *ngIf="!claim?.estimates?.shopsLast?.shop?._id"
                              [resource]="'CONTACT'"
                              [label]="'Truck Shop'"
                              [isReadOnly]="!EDIT_VIEW"
                              [idInfo]="'truck-shop-middle'"
                              [options]="{noFocus: true}"
                              [method]="'search'"
                              [filter]="{type: 'TRUCK SHOP', limit: 25, sort: 'quoteCount', dir: 'desc', active: true, isAccount: true}"
                              (onChange)="setShop('shopsLast', $event)"
                            ></app-search>
                            <app-input
                              *ngIf="claim?.estimates?.shopsLast?.shop?._id"
                              [idInfo]="'last'"
                              [label]="'Truck Shop'"
                              [isReadOnly]="!EDIT_VIEW"
                              [value]="claim?.estimates?.shopsLast?.shop?.name"
                              [options]="{canRemove: EDIT_VIEW}"
                              (onRemove)="deleteShop('shopsLast')"
                            ></app-input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="charges-container claim_info_item claim-cont-item">
          <div class="">
            <div class="flex items-center">
              <p class="item_det-title">Charges</p>
              <span class="updated-date-charges">
                Updated at {{claim?.amounts?.charges?.updatesDate && claim?.amounts?.charges?.updatesDate !== '' ? (claim?.amounts?.charges?.updatesDate | date: 'MM/dd/yyy h:m a') : (claim?.createdAt | date: 'MM/dd/yyy h:m a')}}
              </span>
            </div>


            <div class="charges_list">
              <!-- <div class="claim-payd_item" [ngClass]="{'flex items-center justify-between border-b border-gray-200 pb-3': !EDIT_VIEW}">
                <p class="claim_info-title">How Much Was Paid</p>
                <div class="flex items-center claim_pay_view" *ngIf="!EDIT_VIEW"><i class="fa-solid fa-dollar-sign icon_currency"></i><span>{{claim?.realPaidAmount | myCurrency}}</span></div>
                <app-price
                  *ngIf="EDIT_VIEW"
                  [isReadOnly]="locked || isReadOnly || !EDIT_VIEW"
                  [type]="'price'"
                  [title]="'claim?.realPaidAmount'"
                  [value]="claim?.realPaidAmount"
                  [icon]="'fa-solid fa-dollar-sign'"
                  [options]="{iconCurrency: true}"
                  (onSave)="patchClaim({ realPaidAmount: $event })"
                ></app-price>
              </div> -->


              <div class="charges_item flex items-center justify-between">
                <span class="charges_title">Settlement:</span>
                <div class="prive-charges-wr">
                  <div *ngIf="!EDIT_VIEW" class="flex items-center claim_pay_view"><i class="fa-solid fa-dollar-sign icon_currency"></i><span class="font-bold">{{claim.amounts?.charges?.settlement | myCurrency}}</span></div>
                  <app-price
                    *ngIf="EDIT_VIEW"
                    [isReadOnly]="locked || isReadOnly || !EDIT_VIEW"
                    [invalid]="totalOfResponsibleMinusSettlement"
                    [value]="claim.amounts?.charges?.settlement"
                    [icon]="'fa-solid fa-dollar-sign'"
                    [type]="'price'"
                    [options]="{iconCurrency: true}"
                    (onSave)="patchClaimCharges({ 'amounts.charges.settlement': $event == '' ? 0 : $event })"
                  ></app-price>
                </div>
              </div>
              <div class="charges_item flex items-center justify-between">
                <span class="charges_title">Admin Fee:</span>
                <div class="prive-charges-wr">
                  <div *ngIf="!EDIT_VIEW" class="flex items-center claim_pay_view"><i class="fa-solid fa-dollar-sign icon_currency"></i><span class="font-bold">{{claim.amounts?.charges?.adminFee | myCurrency}}</span></div>
                  <app-price
                    *ngIf="EDIT_VIEW"
                    [isReadOnly]="locked || isReadOnly || !EDIT_VIEW"
                    [value]="claim.amounts?.charges?.adminFee"
                    [icon]="'fa-solid fa-dollar-sign'"
                    [type]="'price'"
                    [options]="{iconCurrency: true}"
                    (onSave)="patchClaimCharges({ 'amounts.charges.adminFee': $event == '' ? 0 : $event })"
                  ></app-price>
                </div>
              </div>
              <div class="charges_item flex items-center justify-between">
                <span class="charges_title">Company Charge:</span>
                <div class="prive-charges-wr">
                  <div *ngIf="!EDIT_VIEW" class="flex items-center claim_pay_view"><i class="fa-solid fa-dollar-sign icon_currency"></i><span class="font-bold">{{claim.amounts?.charges?.company | myCurrency}}</span></div>
                  <app-price
                    *ngIf="EDIT_VIEW"
                    [isReadOnly]="locked || isReadOnly || !EDIT_VIEW"
                    [value]="claim.amounts?.charges?.company"
                    [icon]="'fa-solid fa-dollar-sign'"
                    [type]="'price'"
                    [options]="{iconCurrency: true}"
                    (onSave)="patchClaimCharges({ 'amounts.charges.company': $event == '' ? 0 : $event })"
                  ></app-price>
                </div>
              </div>
              <div class="charges_item flex items-center justify-between">
                <span class="charges_title">Driver Charge:</span>
                <div class="prive-charges-wr">
                  <div *ngIf="!EDIT_VIEW" class="flex items-center claim_pay_view"><i class="fa-solid fa-dollar-sign icon_currency"></i><span class="font-bold">{{claim.amounts?.charges?.driver | myCurrency}}</span></div>
                  <app-price
                    *ngIf="EDIT_VIEW"
                    [isReadOnly]="locked || isReadOnly || !EDIT_VIEW"
                    [value]="claim.amounts?.charges?.driver"
                    [icon]="'fa-solid fa-dollar-sign'"
                    [type]="'price'"
                    [options]="{iconCurrency: true}"
                    (onSave)="patchClaimCharges({ 'amounts.charges.driver': $event == '' ? 0 : $event })"
                  ></app-price>
                </div>
              </div>
            </div>
            <div class="driverNotified">
              <app-btn-toggle
                [checkbox]="true"
                [disabled]="locked || isReadOnly || !EDIT_VIEW"
                [value]="claim?.driverNotified"
                [label]="'Driver Notified Of Deduction'"
                (onToggle)="patchClaim({ driverNotified: $event })"
              >
              </app-btn-toggle>
            </div>
          </div>
          <div class="claim__amounts-container">
            <div class="flex items-center">
              <p class="item_det-title">Settlement</p>
              <span *ngIf="totalOfResponsibleMinusSettlement" class="settlement_warning">settlement does't match</span>
            </div>

            <div class="amounts_list_wrapper">
              <div class="amounts_list" [ngClass]="{'border-none': !claim.amounts?.responsible?.admin}">
                @for(driverId of claim.driversFault; track $index){
                  <div class="amounts_item-driver">
                    <ng-container *ngVar="(claim.amounts?.responsible?.drivers
                      ? claim.amounts?.responsible?.drivers[driverId]
                      : 0) as driverFee">
                      <div class="amounts_item flex items-center justify-between">
                        <div class="flex items-center">
                          <span class="amounts_title">{{getContactById(driverId)?.type | titlecase}}:</span>
                          <span class="amounts_value">{{getContactById(driverId)?.name}}</span>
                        </div>
                        <div class="prive-charges-wr">
                          <div *ngIf="!EDIT_VIEW" class="flex items-center claim_pay_view"><i class="fa-solid fa-dollar-sign icon_currency"></i><span class="font-bold">{{driverFee | myCurrency}}</span></div>
                          <app-price
                            *ngIf="EDIT_VIEW"
                            [isReadOnly]="locked || isReadOnly || !EDIT_VIEW"
                            [value]="driverFee"
                            [type]="'price'"
                            [icon]="'fa-solid fa-dollar-sign'"
                            [options]="{iconCurrency: true}"
                            (onSave)="patchDriverResponsibleAmount(driverId, $event)"
                          ></app-price>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                }
                <div *ngIf="claim?.carrierAtFault" class="amounts_item flex items-center justify-between">
                  <div class="flex items-center">
                    <span class="amounts_title">Carrier:</span>
                    <span class="amounts_value">{{getContactById(carrierId)?.name}}</span>
                  </div>
                  <div class="prive-charges-wr">
                    <div *ngIf="!EDIT_VIEW" class="flex items-center claim_pay_view"><i class="fa-solid fa-dollar-sign icon_currency"></i><span class="font-bold">{{claim.amounts?.responsible?.carrier | myCurrency}}</span></div>
                    <app-price
                      *ngIf="EDIT_VIEW"
                      [isReadOnly]="locked || isReadOnly || !EDIT_VIEW"
                      [value]="claim.amounts?.responsible?.carrier"
                      [type]="'price'"
                      [icon]="'fa-solid fa-dollar-sign'"
                      [options]="{iconCurrency: true}"
                      (onSave)="patchClaim({ 'amounts.responsible.carrier': $event })"
                    ></app-price>
                  </div>
                </div>
                <div *ngIf="claim.driversFault?.length" class="amounts_item flex items-center justify-between">
                  <div class="flex items-center">
                    <span class="amounts_title">Admin:</span>
                    <span class="amounts_value">{{getContactById(carrierId)?.name}}</span>
                  </div>
                  <div class="prive-charges-wr">
                    <div *ngIf="!EDIT_VIEW" class="flex items-center claim_pay_view"><i class="fa-solid fa-dollar-sign icon_currency"></i><span class="font-bold">{{claim.amounts?.responsible?.admin | myCurrency}}</span></div>
                    <app-price
                      *ngIf="EDIT_VIEW"
                      [isReadOnly]="locked || isReadOnly || !EDIT_VIEW"
                      [value]="claim.amounts?.responsible?.admin"
                      [icon]="'fa-solid fa-dollar-sign'"
                      [type]="'price'"
                      [options]="{iconCurrency: true}"
                      (onSave)="patchClaim({ 'amounts.responsible.admin': $event })"
                    ></app-price>
                  </div>
                </div>
                <div *ngIf="claim?.dispatcherAtFault" class="amounts_item flex items-center justify-between">
                  <div class="flex items-center">
                    <span class="amounts_title">Dispatcher:</span>
                    <span *ngIf="claim?.dispatchersAtFault?.length" class="amounts_value">{{getContactById(claim?.dispatchersAtFault[0])?.name}}</span>
                  </div>
                  <div class="prive-charges-wr">
                    <div *ngIf="!EDIT_VIEW" class="flex items-center claim_pay_view"><i class="fa-solid fa-dollar-sign icon_currency"></i><span class="font-bold">{{claim.amounts?.responsible?.dispatcher | myCurrency}}</span></div>
                    <app-price
                      *ngIf="EDIT_VIEW"
                      [isReadOnly]="locked || isReadOnly || !EDIT_VIEW"
                      [value]="claim.amounts?.responsible?.dispatcher"
                      [type]="'price'"
                      [icon]="'fa-solid fa-dollar-sign'"
                      [options]="{iconCurrency: true}"
                      (onSave)="patchClaim({ 'amounts.responsible.dispatcher': $event })"
                    >
                    </app-price>
                  </div>
                </div>
                <div class="amounts_item flex items-center justify-between">
                  <span class="amounts_title">Insurance:</span>
                  <div class="prive-charges-wr">
                    <div *ngIf="!EDIT_VIEW" class="flex items-center claim_pay_view"><i class="fa-solid fa-dollar-sign icon_currency"></i><span class="font-bold">{{claim.amounts?.responsible?.insurance | myCurrency}}</span></div>
                    <app-price
                      *ngIf="EDIT_VIEW"
                      [isReadOnly]="locked || isReadOnly || !EDIT_VIEW"
                      [value]="claim.amounts?.responsible?.insurance"
                      [icon]="'fa-solid fa-dollar-sign'"
                      [type]="'price'"
                      [options]="{iconCurrency: true}"
                      (onSave)="patchClaim({ 'amounts.responsible.insurance': $event })"
                    ></app-price>
                  </div>
                </div>
              </div>
              @for(driverId of claim.driversFault; track $index) {
                <div>
                  <ng-container *ngVar="(claim.amounts?.responsible?.drivers
                    ? claim.amounts?.responsible?.drivers[driverId]
                    : 0) as driverFee">
                    <div *ngIf="claim.amounts?.responsible?.admin" class="settlement_total flex items-center justify-between">
                      <span class="amounts_title">Total:</span>
                      <span class="amounts_value">USD ${{ trunc((driverFee + claim.amounts?.responsible?.admin /
                        claim.driversFault?.length).tf2) | myCurrency }}</span>
                    </div>
                  </ng-container>
                </div>
              }
            </div>
          </div>
          <div class="claim_payment-wr">
            <p class="item_det-title">Payment</p>
            <div class="claim_payment-container">
              <div class="amounts_item flex items-center justify-between">
                <span class="amounts_title paym-title">Name:</span>
                <div class="payment-container-value">
                  <p *ngIf="!EDIT_VIEW" class="amounts_value">{{claim?.payment?.name}}</p>
                  <app-input
                    *ngIf="EDIT_VIEW"
                    [isReadOnly]="locked || isReadOnly || !EDIT_VIEW"
                    [title]="'claim?.payment?.name'"
                    [value]="claim?.payment?.name"
                    [label]="'Name'"
                    [type]="'text'"
                    (onSave)="claim.payment = claim.payment || {}; claim.payment.name = $event; patchClaim({ payment: claim?.payment })"
                  ></app-input>
                </div>
              </div>
              <div class="amounts_item flex items-center justify-between">
                <span class="amounts_title paym-title">Method:</span>
                <div class="payment-container-value">
                  <p *ngIf="!EDIT_VIEW" class="amounts_value">{{claim?.payment?.method}}</p>
                  <app-select
                    *ngIf="EDIT_VIEW"
                    [isReadOnly]="!EDIT_VIEW"
                    (onSave)="claim?.payment.method = $event; patchClaim({ payment: claim?.payment })"
                    [value]="claim?.payment?.method"
                    [list]="paymentMethods"
                    [label]="'Method:'"
                    [placeholder]="'Select Method'"
                    [idInfo]="'claim-paymentMethods'"
                  ></app-select>
                </div>
              </div>
              <div class="amounts_item flex items-center justify-between">
                <span class="amounts_title paym-title">Address:</span>
                <div class="payment-container-value">
                  <p *ngIf="!EDIT_VIEW" class="amounts_value">{{getAddress(claim?.payment?.address)}}</p>
                  <app-address
                    *ngIf="EDIT_VIEW"
                    [isReadOnly]="locked || isReadOnly || !EDIT_VIEW"
                    [title]="'claim?.payment?.address'"
                    [address]="claim?.payment?.address"
                    [options]="{usInput: true}"
                    [label]="'Address'"
                    [type]="'address'"
                    (onSave)="claim.payment = claim.payment || {};
                      claim.payment.address = $event;
                      patchClaim({ payment: claim?.payment })"
                  >
                  </app-address>
                </div>
              </div>
              <div class="mt-3">
                <div class="item-notes relative" [ngClass]="{'bg-gray-50 rounded-lg border border-gray-300 p-2.5': EDIT_VIEW}">
                  <p [ngClass]="[EDIT_VIEW ? 'item-notes-title' : 'item_det-title']">Payment Notes</p>
                  <textarea rows="4" [disabled]="!EDIT_VIEW" [value]="claim?.payment?.notes ? claim?.payment?.notes : ''" (blur)="updatePaymentNote($any($event.target).value)" class="block w-full damage-notes-text text-sm p-0 text-gray-900 border-none focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Type here"
                    [ngClass]="{'edit_textarea_notes': EDIT_VIEW}"
                  ></textarea>
                  <button *ngIf="claim?.payment?.notes?.length" class="item-notes-btn" [popper]="paymentNotes" [popperDisableStyle]="true" [popperPlacement]="NgxPopperjsPlacements.TOP" [popperTrigger]="NgxPopperjsTriggers.click" [popperHideOnClickOutside]="true">
                    <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
                  </button>
                  <popper-content #paymentNotes class="z-50">
                    <div class="rounded-lg bg-white item-notes-popover-wr">
                      <div class="bg-white rounded-lg">
                        <div class="relative">
                          <div class="flex items-center justify-between">
                            <span class="item-notes-title-popover">Payment Notes</span>
                            <button class="item-notes-close" (click)="paymentNotes.hide()">
                              <i class="fas fa-close text-gray-400"></i>
                            </button>
                          </div>
                          <textarea [disabled]="!EDIT_VIEW"
                            (blur)="updatePaymentNote($any($event.target).value)"
                            class="block w-full p-0 item-notes-popover-text bg-white rounded-lg border-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Type here"
                          >{{claim?.payment?.notes ? claim?.payment?.notes : ''}}</textarea>
                        </div>
                      </div>
                    </div>
                  </popper-content>
                </div>
                <!-- <app-notes
                  [isReadOnly]="locked || isReadOnly"
                  [options]="{ list: true }"
                  [quoteId]="claim?._id"
                  [title]="'Payment Notes'"
                  [display]="{
                    tabs: { notes: true },
                    select: {
                      assignee: true,
                      visibility: true,
                      date: true,
                      typeTask: true,
                      complete: true,
                      title: 'Payment Notes'
                    }
                  }"
                  [creator]="currentUser"
                  [notes]="claim?.payment?.notes"
                  [usersEntities]="users"
                  (onCreate)="createNote('payment.notes', $event)"
                  (onUpdate)="updateNote('payment.notes', $event)"
                  (onRemove)="removeNote('payment.notes', $event)"></app-notes> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="claim-footer">
        <div class="claim-footer-left">
          <div class="claim_info_item claim-cont-item w-full relative">
            <div *ngIf="newDocument" class="modal" (click)="newDocument = false"></div>
            <div class="flex font-poppins claim-footer-menu">
              <span [ngClass]="[claimPage == 'notes' ? 'active-link-claim' : 'nav-link-claim']" (click)="claimPage = 'notes'">Notes</span>
              <span [ngClass]="[claimPage == 'tasks' ? 'active-link-claim' : 'nav-link-claim']" (click)="claimPage = 'tasks'">Tasks</span>
              <span *appHasPermission="'notifications_read'" [ngClass]="[claimPage == 'sentNotifications' ? 'active-link-claim' : 'nav-link-claim']" (click)="claimPage = 'sentNotifications'">Sent Notifications</span>
            </div>
            <div *ngIf="!showHistory"
              class="pages-cont">

              <div *ngIf="claimPage == 'notes'">
                <app-notes
                  [isReadOnly]="locked || isReadOnly" [options]="{ list: true }"
                  [quoteId]="claim?._id"
                  [display]="{
                    tabs: { notes: true },
                    select: {
                      assignee: true,
                      visibility: true,
                      date: true,
                      typeTask: true,
                      complete: true
                    }
                  }" [creator]="currentUser" [usersEntities]="users"
                  [resource]="'CLAIM'"
                  [resourceId]="claim?._id"
                  ></app-notes>
                <!--end:: Widgets/Notes -->
              </div>
              <div *ngIf="claimPage == 'tasks'">
                <app-tasks-container
                  [currentUser]="currentUser"
                  [resource]="{
                  resource: FOLLOW_UP_RESOURCE.CLAIM,
                  resourceId: claim._id,
                  resourceName: claim?.autoIncr+''
                }"
                ></app-tasks-container>
              </div>
              <div *ngIf="claimPage == 'sentNotifications'">
                <div *appHasPermission="'notifications_read'">
                  <app-send-notifications [notifications]="notifications"></app-send-notifications>
                </div>
              </div>

              <!--

                ?????

              -->
              <!-- <div class="col-md-7">
                <div class="p-3 d-flex">
                  <div class="w-50">
                    <app-diesel-chat STORE_NAME="CORE"
                      [options]="{ style: { height: '300px' }, edit: true, delete: true, create: true, archive: true }"
                      [currentUser]="currentUser" [resource]="FOLLOW_UP_RESOURCE.CLAIM" [resourceId]="claim._id" [resourceName]="claim?.autoIncr+''">
                    </app-diesel-chat>
                  </div>
                </div>
              </div> -->
              <!--

                ?????

              -->
            </div>
          </div>
        </div>
        <div class="claim-footer-right">
          <div class="claim_info_item claim-cont-item flex flex-col justify-between">
            <div *ngIf="newDocument" class="documents-edit-wr" (click)="$event.stopPropagation()">
              <div class="flex items-center justify-between">
                <p class="plus-type-title">Checklist</p>
              </div>
              <div class="checklist_search flex items-center">
                <input type="text" id="checklist_search" [(ngModel)]="documentType" class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <button class="check-type" (click)="addDocumentType()">
                  <i class="fa-solid fa-check"></i>
                </button>
              </div>
              <div class="checklist_documents-edit">
                @for(type of claim.checklist; track $index){
                  <div class="checklist-item-edit flex items-center justify-between">
                    <div class="flex items-center">
                      <input
                        class="custom-checkbox w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                        type="checkbox"
                        [attr.disabled]="locked || isReadOnly"
                        [(ngModel)]="type.completed"
                        (click)="addActiveDocument(type)"
                        placeholder="Enter Text"
                        id="`document {{ type.name }}`"
                      />
                      <span>{{ type.name }}</span>
                    </div>
                    <div class="ml-2"
                      *ngIf="!(locked || isReadOnly)">
                      <app-btn-confirm
                        (onConfirm)="removeChecklistItem(type)"
                      >
                      </app-btn-confirm>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div *ngIf="!newDocument">
              <div>
                <p class="item_det-title">Documents Checklist</p>
                <div class="checklist_documents">
                  @for(type of claim.checklist; track $index){
                    <div class="checklist-item flex items-center">
                      <input
                        class="custom-checkbox w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                        type="checkbox"
                        [attr.disabled]="locked || isReadOnly"
                        [(ngModel)]="type.completed"
                        (click)="addActiveDocument(type)"
                        placeholder="Enter Text"
                        id="`document {{ type.name }}`"
                      />
                      <span>{{ type.name | titlecase }}</span>
                    </div>
                  }
                </div>
              </div>
              <div *ngIf="!(locked || isReadOnly)">
                <!-- <button class="add-new-type" *ngIf="!newDocument" (click)="newDocument = !newDocument">
                  + Add New Type
                </button>
                <button class="add-new-type" *ngIf="newDocument" (click)="addDocumentType(); newDocument = !newDocument">
                  <input [(ngModel)]="documentType" type="text" />
                  + Add
                </button> -->
                <button class="add-new-type" *ngIf="!newDocument" (click)="newDocument = !newDocument">
                  + Add New Type
                </button>
                <button class="add-new-type" *ngIf="newDocument" (click)="addDocumentType(); newDocument = !newDocument">
                  <input [(ngModel)]="documentType" type="text" />
                  + Add
                </button>
              </div>
            </div>
          </div>
          <div class="claim_info_item claim-cont-item relative">
            <div *ngIf="newDocument" class="modal" (click)="newDocument = false"></div>
            <div class="font-medium text-center text-gray-500 mb-4">
              <div class="flex font-poppins claim-footer-menu">
                <span [ngClass]="[fileView == 'claim' ? 'active-link-claim' : 'nav-link-claim']" (click)="fileView = 'claim'">Files</span>
                <span *ngIf="order?._id" [ngClass]="[fileView == 'order' ? 'active-link-claim' : 'nav-link-claim']" class="ml-2" (click)="fileView = 'order'">Order Files</span>
                <span *ngIf="claim?._local?.expense?._id" [ngClass]="[fileView == 'expense' ? 'active-link-claim' : 'nav-link-claim']" class="ml-2" (click)="fileView = 'expense'">Expense Files</span>
              </div>
            </div>
            <div *ngIf="fileView == 'claim'">
              <app-files
                [isReadOnly]="locked || isReadOnly"
                [resource]="FOLLOW_UP_RESOURCE.CLAIM"
                [resourceId]="claim?._id"
                [lock]="locked"
                (onSend)="sendFiles($event)"
                [idInfo]="'clain-files'"
              ></app-files>
            </div>
            <div *ngIf="order?._id && fileView == 'order'">
              <app-files
                [title]="'Order files'"
                [isReadOnly]="true"
                [resource]="FOLLOW_UP_RESOURCE.ORDER"
                [resourceId]="order?._id"
                [options]="{ showSelection: false }"
                [idInfo]="'order-files'"
                [lock]="locked"
              ></app-files>
            </div>
            <div *ngIf="claim?._local?.expense?._id && fileView == 'expense'">
              <app-files
                [title]="'Expense files'"
                [isReadOnly]="true"
                [resource]="FOLLOW_UP_RESOURCE.EXPENSE"
                [resourceId]="claim?._local?.expense?._id"
                [options]="{ showSelection: false }"
                [lock]="locked"
                [idInfo]="'expense-files'"
              ></app-files>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

