import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import * as fromCoreStore from '@app/core/store'
import * as fromModalStoreActions from '@app/modals/store/actions'
import { Subject, takeUntil } from 'rxjs'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import moment from 'moment'
import { OrderService } from '@app/core/services'
import { popupService } from '@app/shared/services/popup.service'
import { Order } from '@app/shared/models'

@Component({
  selector: 'app-follow-up-modal',
  templateUrl: './follow-up-modal.component.html',
  styleUrls: ['./follow-up-modal.component.scss']
})
export class FollowUpModalComponent implements OnInit, OnDestroy {
  modaltype = ''
  types = ''
  noTag = false
  editHistory = false
  follow = false
  quote: Order
  @Input()
  set props(val) {
    if (val) {
      // console.log(val)
      this.modaltype = val?.type
      // edit_empty_schedule
      if (val?.followUp?.types) {
        this.types = val?.followUp?.types?.join(", ")
      }
      if (val?.quote) {
        this.quote = val?.quote
      }
      if (val?.noTag) {
        this.noTag = val?.noTag
      }
      if (val?.editHistory) {
        this.editHistory = val?.editHistory
      }

      if (val.followUp?.sent) {
        this.form.patchValue({
          sent: val.followUp.sent
        })
      }

      if (val?.billingContact) {
        this.contact = val?.billingContact
        this.form.patchValue({
          creator: {
            _id: val?.billingContact?._id,
            name: val?.billingContact?.name,
            companyId: val?.billingContact?.creator?.companyId
          }
        })
      }
      if (this.modaltype == 'edit') {
        this.form.patchValue({
          subject: val.followUp.subject || ''
        })
      }

      if (this.modaltype == 'edit_empty_schedule') {
        this.form.patchValue({
          phones: val.followUp?.phones || [],
          emails: val.followUp?.emails || [],
          sentNow: false,
        })
      }

      this.canDelete = val?.canDelete

      if (this.modaltype !== 'edit') {
        const formattedDate = moment()
        this.form.patchValue({
          sentAt: formattedDate,
        })
      } else {
        this.form.patchValue({
          sentAt: moment(val.followUp.sentAt),
        })
      }

      if (val?.orderId) {
        this.setCurrentOrder(val.orderId)
      }
      this.isTask = val?.followUp?.isTask
      if (val.followUp?._id) {
        if (!val.followUp?.resourceId) {
          this.follow = true
        }
        this.form.patchValue({
          types: val.followUp.types || [],
          sentAt: moment(val.followUp.sentAt) || '',
          description: val.followUp.description || '',
          outcome: val.followUp.outcome || '',
          direction: val.followUp.direction || '',
          orderId: val.orderId || '',
          subject: val.followUp.subject || '',
          templateId: val.followUp?.templateId || '',
          emailBody: val.followUp?.body || val.followUp?.emailBody || '',
          smsBody: val.followUp?.body || val.followUp?.smsBody || '',
          _id: val.followUp?._id,
          filteredTypes: val.followUp.types?.filter(n => n !== 'Call'),
          filledLater: val.followUp?.filledLater || false,
          emails: val.followUp?.emails || [],
          phones: val.followUp?.phones || [],
        })

        if (val?.followUp?.additionalData && (val?.followUp?.additionalData?.followUpCount || val?.followUp?.additionalData?.followUpCount == 0)) {
          this.form.patchValue({
            additionalData: {
              followUpCount: val?.followUp?.additionalData?.followUpCount
            }
          })
        }

        if (val.followUp?.tag || val.followUp?.additionalData?.tag) {
          this.form.patchValue({
            tag: val.followUp?.tag || val.followUp?.additionalData?.tag,
          })
        }

        if (val.followUp?.isFollowUp) {
          this.isFollowUp = val.followUp?.isFollowUp
        }

        if (val.followUp.types[0] == 'Call' && val.followUp.types?.length == 1) {
          this.form.patchValue({
            phones: val.followUp?.additionalData?.phones || val.followUp?.phones || [],
            creator: val.followUp.creator ? val.followUp.creator : val.followUp.assignees[0]
          })
        }
        if (val.followUp.types[0] == 'Email' && val.followUp.types?.length == 1) {
          this.form.patchValue({
            emails: val.followUp?.to || val.followUp?.emails || [],
            creator: val.followUp.creator
          })
        }
        if (val.followUp.types[0] == 'SMS' && val.followUp.types?.length == 1) {
          this.form.patchValue({
            phones: val.followUp?.to || val.followUp?.phones || [],
            creator: val.followUp.creator
          })
        }
        this.cdr.detectChanges()
      } else {
        if (this.isTask) {
          this.form.patchValue({
            orderId: val.followUp?.resourceId
          })
        } else {
          this.form.patchValue({
            orderId: val?.orderId,
          })
        }
        this.cdr.detectChanges()
      }

      if (this.modaltype == 'touchPoint') {
        this.form.patchValue({
          sentNow: true
        })
      }

      if (this.modaltype !== 'edit' && this.modaltype !== 'edit_empty_schedule') {
        const valuesEmails = val?.billingContact?.emails.map(item => item.value) || []
        const valuesPhones = val?.billingContact?.phones.map(item => item.value) || []
        this.form.patchValue({
          emails: valuesEmails,
          phones: valuesPhones,
        })
      }
    }
  }
  isTask = false
  isFollowUp = false
  contant = null

  type = 'phone'
  inputMasks = {
    phone: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  }
  canDelete = true
  contact = null
  times = []
  timeTypes = [{ key: 'AM', name: 'AM' }, { key: 'PM', name: 'PM' }]
  touchpoints = [{ key: 'Call', name: 'Call' }, { key: 'SMS', name: 'SMS' }, { key: 'Email', name: 'Email' }]
  direction = [{ key: 'Inbound', name: 'Inbound' }, { key: 'Outbound', name: 'Outbound' }]
  outcomeGreen = [
    { key: 'Quote', name: 'Quote', color: '#046C4E', bg: '#DEF7EC' },
    { key: 'Future Shipment', name: 'Future Shipment', color: '#046C4E', bg: '#DEF7EC' },
    { key: 'Will Call Back', name: 'Will Call Back', color: '#046C4E', bg: '#DEF7EC' }
  ]
  outcomeYellow = [
    { key: 'Left Message', name: 'Left Message', color: '#8E4B10', bg: '#FEECDC' },
    { key: 'Could Not Reach', name: 'Could Not Reach', color: '#8E4B10', bg: '#FEECDC' },
    { key: 'Shopping Around', name: 'Shopping Around', color: '#8E4B10', bg: '#FEECDC' }
  ]
  outcomeRed = [
    { key: 'Hired Other Company', name: 'Hired Other Company', color: '#C81E1E', bg: '#FDE8E8' },
    { key: 'No Longer Shipping', name: 'No Longer Shipping', color: '#C81E1E', bg: '#FDE8E8' },
    { key: 'Bad Phone', name: 'Bad Phone', color: '#C81E1E', bg: '#FDE8E8' },
  ]
  typesWarning = false
  dateWarning = false
  emailsPhonesWarning = false
  smsWarning = false
  emailWarning = false
  templates = []
  currentOrder = null

  destroyed$ = new Subject<boolean>()
  form: FormGroup

  constructor(
    private store: Store<fromCoreStore.State>,
    private cdr: ChangeDetectorRef,
    private _OrderService: OrderService,
    private fb: FormBuilder,
    private popupService: popupService
  ) {
    this.form = this.fb.group({
      types: [[], Validators.required],
      sentAt: ['', Validators.required],
      emails: [[], [Validators.required, Validators.email]],
      phones: [[], Validators.required],
      description: ['', Validators.required],
      creator: [{}, Validators.required],
      outcome: [''],
      direction: ['Outbound'],
      orderId: [''],
      subject: [''],
      emailBody: ['', Validators.required],
      smsBody: ['', Validators.required],
      templateId: [''],
      tag: [null],
      _id: [''],
      filteredTypes: [[]],
      filledLater: [false],
      sentNow: [null],
      additionalData: [{}],
      sent: [false],
    })
  }

  ngOnInit(): void {
    this.times = this.generateTimeIntervals()

    this.store.dispatch(new fromCoreStore.GetTemplates({ fields: ['name', 'order'], 'devices': 'web' }))

    this.store.select(fromCoreStore.selectCompiledTemplate)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(template => {
        if (template) {
          this.form.patchValue({
            subject: template.subject,
            smsBody: template.sms,
            emailBody: template.email,
            templateId: template._id
          })
        }
      })
    // this.store.select(fromCoreStore.selectCompiledTemplate)
    //   .pipe(takeUntil(this.destroyed$))
    //   .subscribe(template => {
    //     if (template) {
    //       this.form.patchValue({
    //         subject: template.subject,
    //         smsBody: template.sms,
    //         emailBody: template.email,
    //         templateId: template.templateId
    //       })
    //     }
    //   })

    this.store.select(fromCoreStore.selectAllTemplates)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(templates => {
        if (templates) {
          this.templates = templates
          this.cdr.detectChanges()
        }
      })
  }

  close() {
    this.store.dispatch(new fromModalStoreActions.CloseModal())
  }

  changeTemplate(_id: string) {
    if (_id) {
      this.store.dispatch(new fromCoreStore.CompileTemplate({ _id, data: this.currentOrder }))
    } else {
      this.store.dispatch(new fromCoreStore.CompileTemplateSuccess({
        template: {
          _id: '',
          subject: '',
          sms: '',
          email: ''
        }
      }))
    }
  }

  changeDate(event: string) {
    const currentSentAt = this.form.get('sentAt')?.value
    const currentMoment = moment(currentSentAt).utc()
    const newDate = moment(event)

    currentMoment.set({
      year: newDate.year(),
      month: newDate.month(),
      date: newDate.date(),
    })

    this.form.get('sentAt')?.setValue(currentMoment.toISOString())
  }

  onChangeTypes(type) {
    const typesArray = this.form.get('types')?.value || [];

    if (typesArray.includes(type)) {
      const updatedArray = typesArray.filter(item => item !== type);
      this.form.get('types')?.setValue(updatedArray);
    } else {
      this.form.get('types')?.setValue([...typesArray, type]);
    }

    if (this.modaltype == 'touchPoint' || this.modaltype == 'history') {
      if (this.contact?.phones?.length) {
        if (this.form.get('types')?.value?.includes('SMS') || this.form.get('types')?.value?.includes('Call')) {
          let phones = [this.contact?.phones[0]?.value]
          this.form.patchValue({
            phones
          })
        } else {
          this.form.patchValue({
            phones: []
          })
        }
      }
      if (this.contact?.emails?.length) {
        if (this.form.get('types')?.value?.includes('Email')) {
          let emails = [this.contact?.emails[0]?.value]
          this.form.patchValue({
            emails
          })
        } else {
          this.form.patchValue({
            emails: []
          })
        }
      }
    }

    if (this.form.get('types')?.value?.length) {
      this.form.patchValue({
        filteredTypes: this.form.get('types')?.value.filter(n => n !== 'Call')
      })
    } else {
      this.form.patchValue({
        filteredTypes: []
      })
    }
  }

  onChangeOutcome(event) {
    const outcomeControl = this.form.get('tag');
  
    if (outcomeControl?.value === event) {
      outcomeControl.setValue(null)
    } else {
      outcomeControl.setValue(event)
    }
  }

  onSaveTime(event: string) {
    const currentDate = this.form.get('sentAt').value
    const momentDate = moment(currentDate).utc()

    const currentAmPm = momentDate.format('A')
    const [newHours, newMinutes] = event.split(':').map(Number)

    let adjustedHours = newHours

    if (currentAmPm === 'PM' && newHours < 12) {
      adjustedHours += 12
    } else if (currentAmPm === 'AM' && newHours === 12) {
      adjustedHours = 0
    }

    momentDate.set({
      hour: adjustedHours,
      minute: newMinutes,
      second: 0,
      millisecond: 0
    })

    this.form.get('sentAt')?.setValue(momentDate.toISOString())
  }
  async deleteFollowUp(item) {
  
    let type = ''
    if (item?.types[0] == "Call") {
      type = 'task'
    } else {
      type = 'schedule'
    }
    
    if (this.isFollowUp) {
      type = 'log'
    }
    
    if (item?.filledLater) {
      type = 'edit_empty_schedule'
    }

    if ((await this.popupService.confirm('Are you sure you want to delete?'))) {
      this.store.dispatch(new fromCoreStore.DeleteFollowUp({ item, fields: {type, orderId: this.quote?._id} }))
      this.close()
    }
        
  }

  onSaveTimeType(event: string) {
    const currentDate = this.form.get('sentAt').value;
    const momentDate = moment(currentDate)
  
    if (event === 'AM') {
      if (momentDate.hour() >= 12) {
        momentDate.subtract(12, 'hours');
      }
    } else if (event === 'PM') {
      if (momentDate.hour() < 12) {
        momentDate.add(12, 'hours');
      }
    }
  
    this.form.get('sentAt')?.setValue(momentDate.toISOString());
  }

  async setCurrentOrder(_id) {
    this.currentOrder = (await this._OrderService.get(_id, {
      populate: [
        '_local.carrier.contacts',
        '_local.billing.contacts',
        '_local.vehicles',
        '_local.origin',
        '_local.destination'
      ]
    }).toPromise())?.data

    this.currentOrder = {
      ...this.currentOrder,
      billing: {
        ...this.currentOrder.billing,
        accountContact: this.currentOrder._local?.billing?.contacts?.find(c => c._id === this.currentOrder.billing.accountId),
        contacts: this.currentOrder._local?.billing?.contacts || [],
      },
      carrier: {
        ...this.currentOrder.carrier,
        accountContact: this.currentOrder._local?.carrier?.contacts?.find(c => c._id === this.currentOrder.carrier.accountId),
        contacts: this.currentOrder._local?.billing?.contacts || [],
      },
      vehicles: this.currentOrder._local?.vehicles || [],
      origin: this.currentOrder._local?.origin,
      destination: this.currentOrder._local?.destination,
    }
  }

  send() {
    if (this.modaltype == 'edit') {
      this.typesWarning = this.form.get('types')?.invalid

      if (this.form.get('filledLater')?.value == false && !this.isFollowUp) {
        this.dateWarning = this.form.get('sentAt')?.invalid
        this.smsWarning = this.form.get('smsBody')?.invalid
        this.emailWarning = this.form.get('emailBody')?.invalid
        this.emailsPhonesWarning = this.form.get('emails')?.invalid && this.form.get('phones')?.invalid

        if (this.form.get('types')?.invalid || this.form.get('sentAt')?.invalid || (this.form.get('types')?.value?.includes('SMS') && this.form.get('smsBody')?.invalid) || (this.form.get('types')?.value?.includes('Email') && this.form.get('emailBody')?.invalid)) {
          return
        }

        if ((this.form.get('types')?.value?.includes('Email') && !this.form.get('emails')?.value?.length) || this.form.get('types')?.value?.includes('SMS') && !this.form.get('phones')?.value?.length || this.form.get('types')?.value?.includes('Call') && !this.form.get('phones')?.value?.length) {
          return
        }
      }  
      if (this.form.get('types')?.invalid) {
        return
      }
  
      let item = { ...this.form.value }

      delete item?.additionalData

      if (this.isTask) {
        item = {
          ...item,
          resourceId: item?.orderId,
          additionalData: {
            ...item?.additionalData || {},
            phones: item?.phones.filter(i => i !== 'Call') || []
          }
        }
      }

      delete item?.filteredTypes
      this.store.dispatch(new fromCoreStore.UpdateFollowUp({ item, type: this.isFollowUp ? 'followUp': (!this.isTask ? 'schedule' : 'task') }))
      this.store.dispatch(new fromCoreStore.CleanTemplateStore())
      this.close()
    }

    if (this.modaltype == 'edit_empty_schedule') {

      this.typesWarning = this.form.get('types')?.invalid

      if (!this.form.get('filledLater')?.value) {
        this.dateWarning = this.form.get('sentAt')?.invalid
        this.smsWarning = this.form.get('smsBody')?.invalid
        this.emailWarning = this.form.get('emailBody')?.invalid

        if (this.form.get('types')?.invalid || this.form.get('sentAt')?.invalid || (this.form.get('types')?.value?.includes('SMS') && this.form.get('smsBody')?.invalid) || (this.form.get('types')?.value?.includes('Email') && this.form.get('emailBody')?.invalid) || (this.form.get('emails')?.invalid && this.form.get('phones')?.invalid)) {
          return
        }
      }
  
      if (this.form.get('types')?.invalid) {
        return
      }
  
      let item = { ...this.form.value }
      delete item?.additionalData
      delete item?.filteredTypes
      delete item?.sentNow

      this.store.dispatch(new fromCoreStore.UpdateFollowUp({ item, sentNow: this.form.value.sentNow, type: !item?.filledLater && !this.follow ? 'schedule' : 'edit_empty_schedule' }))
      this.store.dispatch(new fromCoreStore.CleanTemplateStore())
      this.close()
    } 


    if (this.modaltype == 'touchPoint') {
      this.typesWarning = this.form.get('types')?.invalid

      if (this.form.get('sentNow').value) {
        this.form.get('sentAt')?.setValue(moment().toISOString());
      }

      if (this.form.get('filledLater')?.value == false) {
        this.dateWarning = this.form.get('sentAt')?.invalid
        this.emailsPhonesWarning = this.form.get('emails')?.invalid && this.form.get('phones')?.invalid
        this.smsWarning = this.form.get('smsBody')?.invalid
        this.emailWarning = this.form.get('emailBody')?.invalid
    
        if (this.form.get('types')?.invalid || this.form.get('sentAt')?.invalid || (this.form.get('types')?.value?.includes('SMS') && this.form.get('smsBody')?.invalid) || (this.form.get('types')?.value?.includes('Email') && this.form.get('emailBody')?.invalid) || (this.form.get('emails')?.invalid && this.form.get('phones')?.invalid)) {
          return
        }
      }

      if (this.form.get('types')?.invalid) {
        return
      }
  
      let item = { ...this.form.value }

      delete item?.additionalData
      delete item?.filteredTypes

      if (this.form.get('filledLater')?.value) {
        delete item?._id
      }

      if (!this.form.get('filledLater')?.value) {
        delete item?.emailBody
        delete item?.smsBody
        delete item?.templateId
        delete item?.sentNow
      }
  
      this.store.dispatch(new fromCoreStore.CreateFollowUp({
        item,
        type: this.form.get('filledLater')?.value ? 'edit_empty_schedule' : 'schedule',
        emailBody: this.form.value.emailBody,
        smsBody: this.form.value.smsBody,
        templateId: this.form.value.templateId,
        sentNow: this.form.value.sentNow
      }))
      this.store.dispatch(new fromCoreStore.CleanTemplateStore())
      this.close()
    } 

    if (this.modaltype == 'history') {
      this.typesWarning = this.form.get('types')?.invalid

      if (this.form.get('filledLater')?.value == false) {
        this.dateWarning = this.form.get('sentAt')?.invalid
        this.emailsPhonesWarning = this.form.get('emails')?.invalid && this.form.get('phones')?.invalid
    
        if (this.form.get('types')?.invalid || this.form.get('sentAt')?.invalid || (this.form.get('emails')?.invalid && this.form.get('phones')?.invalid)) {
          return
        }
      }

      if (this.form.get('types')?.invalid) {
        return
      }
  
      delete this.form.value?._id
      delete this.form.value?.filteredTypes
      delete this.form.value?.additionalData

      function formatString(str) {
        return str.toUpperCase().replace(/\s+/g, '_')
      }

      let callOutCome = [...(this.quote?.callOutCome || [])];
      if (this.form.value?.tag && this.form.value?.tag?.length) {
          const result = formatString(this.form.value?.tag);
          if (!callOutCome?.includes(result)) {
              callOutCome.push(result)
          }
      }

      if (callOutCome?.length > (this.quote?.callOutCome?.length || 0)) {
        this.store.dispatch(
          new fromCoreStore.PatchOrder({
            order: {
              id: this.quote._id,
              changes: {
                _id: this.quote._id,
                callOutCome
              },
            },
            customPopulate: []
          })
        )
      }
      this.store.dispatch(new fromCoreStore.CreateFollowUp({ item: this.form.value, type: 'log' }))
      this.store.dispatch(new fromCoreStore.CleanTemplateStore())
      this.close()
    } 
  }

  generateTimeIntervals(): { key: string, name: string }[] {
    const intervals: { key: string, name: string }[] = []
    let hour = 0
    let minute = 0

    while (hour < 12) {
      const formattedTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`
      intervals.push({ key: formattedTime, name: formattedTime })

      minute += 5
      if (minute === 60) {
        minute = 0
        hour += 1
      }
    }

    return intervals
  }

  truncateString(str) {
    if (str?.length > 30) {
      return str.slice(0, 30) + '...'
    }
    return str
  }

  onPhoneCheckboxChange(event: any, phone: string) {
    let phonesArray = this.form.get('phones')?.value || []

    if (event.target.checked) {
      phonesArray = []
      phonesArray.push(phone)
    } else {
      const index = phonesArray.indexOf(phone)
      if (index > -1) {
        phonesArray.splice(index, 1)
      }
    }

    if (this.form.get('types')?.value.includes('Call') && phonesArray?.length) {
      const phoneNumbers = this.form.get('phones')?.value.filter(item => item !== 'Call').join(', ')
      this.form.get('description')?.setValue(`Need to call ${phoneNumbers}`)
    } else {
      this.form.get('description')?.setValue('')
    }



    this.form.get('phones')?.setValue(phonesArray)
  }

  onEmailCheckboxChange(event: any, email: string) {
    let emailsArray = this.form.get('emails')?.value || []

    if (event.target.checked) {
      emailsArray = []
      emailsArray.push(email)
    } else {
      const index = emailsArray.indexOf(email)
      if (index > -1) {
        emailsArray.splice(index, 1)
      }
    }

    this.form.get('emails')?.setValue(emailsArray)
  }

  applyMask(input, mask) {
    const inputChars = input.replace(/-/g, '').split('')
    let result = ''
    let inputIndex = 0

    for (let i = 0; i < mask.length; i++) {
      if (inputIndex < inputChars.length) {
        if (mask[i] instanceof RegExp) {
          if (mask[i].test(inputChars[inputIndex])) {
            result += inputChars[inputIndex]
            inputIndex++
          }
        } else {
          result += mask[i]
        }
      }
    }

    return result
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.complete()
  }


}
